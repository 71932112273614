/**
 * 
 * @authors wangfeiran@qiyi.com
 * @date    2020-12-28 15:12:04
 * @desc    子应用注册信息
 */

/**
* name: 微应用名称 - 具有唯一性
* entry: 微应用入口 - 通过该地址加载微应用
* container: 微应用挂载节点 - 微应用加载完成后将挂载在该节点上
* activeRule: 微应用触发的路由规则 - 触发路由规则后将加载该微应用
*/
//本地测试文件
const apps = [
	{
		name: "inner-manager",
		entry: process.env.VUE_APP_SUB_APPLICATION_PRE + "translate-sub-iqiyi-admin/",
		container: "#frame",
		activeRule: "/inner-manager"
	},
	{
		name: "crowd-sourcing",
		entry: process.env.VUE_APP_SUB_APPLICATION_PRE + "translate-sub-crowd-souring/",
		container: "#frame",
		activeRule: "/crowd-sourcing"
	},
	/*{
		name: "live-manage",
		entry: process.env.VUE_APP_SUB_APPLICATION_PRE + "translate-sub-live-manage/",
		container: "#frame",
		activeRule: "/live"
	},*/
	{
		name: "cooperation",
		entry: process.env.VUE_APP_SUB_APPLICATION_PRE + "translate-sub-cooperation/",
		container: "#frame",
		activeRule: "/cooperation"
	},
	{
		name: "caption",
		entry: process.env.VUE_APP_SUB_APPLICATION_PRE + "translate-sub-caption/",
		container: "#frame",
		activeRule: "/caption"
	},
	{
		name: "vc",
		entry: process.env.VUE_APP_SUB_APPLICATION_PRE + "translate-sub-vc/",
		container: "#frame",
		activeRule: "/vc"
	}
]

export default apps