/**
 * 
 * @authors wails (w86534309@gmail.com)
 * @date    2019-07-13 11:04:10
 * @version 1.0
 * @desc 路由加载前的路由守卫
 */
import store from '@/store/';
import utils from '@/common/utils';
import _ from 'lodash';
//eslint-disable-next-line
export default async (to, from, next) => {
	if (to.matched.length > 0) {
		const matchedListMeta = _.cloneDeep(to.matched[0].meta);
		let meta = _.extend(matchedListMeta, to.meta);
		//第三方页面不需要获取用户信息
		if (!meta.thirdPard) {
			if (!store.state.user.uid) {
				await store.dispatch('getUserInfo', {
					path: utils.router.getName(to.path)
				});
			}
			await store.dispatch('getBaseInfo', {
				path: utils.router.getName(to.path)
			});
		}

		if (meta.requiresAuth) {
			let allow = await store.dispatch('getMenuAllow', {
				menu: utils.router.getName(to.path)
			});
			store.state.allow = allow;
		} else {
			store.state.allow = true;
		}
	}

  if (process.env.NODE_ENV === 'production') {
    window.analyticsApplication.send({
      router_path: to.path,
      router_name: to.name,
      router_fullPath: to.fullPath,
      eventType: 'page_view'
    })
  }

	next();
};
