<template>
	<div class="input-datetime-group">
		<date-time-item v-model="timestamp.hour" :disabled="disabled" :max="30"></date-time-item>
		<div class="d-flex align-center">:</div>
		<date-time-item v-model="timestamp.min" :disabled="disabled" :max="60"></date-time-item>
		<div class="d-flex align-center">:</div>
		<date-time-item v-model="timestamp.s" :disabled="disabled" :max="60"></date-time-item>
		<div class="d-flex align-center">.</div>
		<date-time-item v-model="timestamp.ms" :disabled="disabled" :max="1000" :each="10"></date-time-item>
	</div>
</template>

<script type="text/javascript">
	import DateTimeItem from "./DateTimeItem.vue"
	export default {
		name : "date-time-input",
		components : {
			DateTimeItem
		},
		props : {
			value : {
				type : [Number , String],
				default : 0
			},
			disabled : {
				type : Boolean,
				default : false
			}
		},
		data (){
			return {
				timeCopy : _.cloneDeep(this.value),
				timestamp : this.getTimeStamp()
			}
		},
		watch : {
			timestamp : {
				handler(val){
					let time = val.ms + val.s * 1000 + val.min * 60 * 1000 + val.hour * 60 * 60 * 1000;
					this.$emit("input" , time);
				},
				deep : true
			},
			value (val) {
				if(val != this.timeCopy){
					this.timeCopy = val;
					this.timestamp = this.getTimeStamp();
				}
			}
		},
		methods : {
			getTimeStamp (){
				let timestamp = this.value;
				let time = {};
				time.ms = timestamp % 1000;
				time.s = Math.floor(this.value / 1000) % 60
				time.min = Math.floor(this.value / (1000 * 60)) % 60;
				time.hour = Math.floor(this.value / (1000 * 60 * 60))
				return time;
			}
		}
	};
</script>

<style lang="scss">
	.input-datetime-group{
		display: flex;
		justify-content: space-between;
		.input-datetime{
			
		}
	}
</style>