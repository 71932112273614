/**
 * 
 * @authors wangfeiran@qiyi.com
 * @date    2021-01-12 16:50:21
 * @version 1.0
 */

import Vue from 'vue';
import Vuetify from 'vuetify';
import "vuetify/dist/vuetify.min.css"
import zhHans from 'vuetify/es5/locale/zh-Hans'
import En from "vuetify/es5/locale/en"

Vue.use(Vuetify);
console.log(Vuetify)
zhHans.noDataText = "暂无数据";//提示与element保持一致

export default new Vuetify({
  icons: {
	iconfont: 'md',
  },
  lang: {
	locales: { 
		"zh-cn" : zhHans,
		"en-us" : En
	},
	current: 'zh-cn',
  },
});
