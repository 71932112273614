<template>
	<fragment>
		<slot v-if="isShow"></slot>
		<div v-else-if="showDenyTip">
			{{denyTip}}
		</div>
	</fragment>	
	
</template>

<script type="text/javascript">
	export default {
		name : "permission-item",
		props : {
			name : String,
			denyTip : {
				type : String,
				default : "暂无权限"
			},
			showDenyTip : {
				type : Boolean,
				default : false
			}
		},
		computed : {
			isShow (){
				if(this.name){
					var item = this.$store.state.blackItem.filter(val=>{
						return val.name == this.name
					})

					return item.length == 0
				}
				return false;
			}
		}
	}
</script>