/**
 * 
 * @authors Wangfeiran (wangfeiran@qiyi.com)
 * @date    2019-10-18 14:26:43
 * @version 1.0.0
 * @desc 项目菜单key
 */

import livePageName from './livePageName';
import crowdsourcingPageName from './crowdsourcingPageName';
import translateTextPageName from './translateTextPageName';
import vcPageName from './vcPageName';

export default [
	{
		name : "translate",
		router : "/",
		lang_key : "ECHO_TRANSLATION_PLATFORM_1610293656519_389"
	},
  
	//爱奇艺管理员下菜单
	{
		name : "qipu_ocr_task",
		router : "/inner-manager/qipu-ocr-task",
	},
	{
		name : "translate_project",
		router : "/inner-manager/translate-project",
	},
	{
		name : "translate_project_log",
		router : "/inner-manager/translate-project-log",
	},
	{
		name : "data_correction",
		router : "/inner-manager/data-correction",
	},
	{
		name : "quality_project",
		router : "/inner-manager/quality-project"
	},
	{
		name : "translate_task",
		router : "/inner-manager/video-task",
	},
	{
		name : "video_mark_task",
		router : "/inner-manager/video-mark-task",
	},
	{
		name : "llm_translate_task",
		router : "/inner-manager/llm-translate-task",
	},
	{
		name : "media_resource",
		router : "/inner-manager/media-video"
	},
	{
		name : "my_page",
		router : "/inner-manager/dashboard"
	},
	{
		name : "quality_task",
		router : "/inner-manager/quality-task",
	},
	{
		name : "quality_task_detail",
		router : "/inner-manager/quality-task-detail",
	},
	{
		name : "translate_video",
		router : "/inner-manager/video-list"
	},
	{
		name : "partner_manage",
		router : "/inner-manager/employee-manage"
	},
	{
		name : "partner_manage_deviate",
		router : "/inner-manager/employee-manage-deviation"
	},
	{
		name : "quality_error_config",
		router : "/inner-manager/quality-error-config"
	},
	{
		name : "cooperation_subtitle",
		router : "/inner-manager/cooperation-subtitle"
	},
	{
		name : "subtitle_strategy_config",
		router : "/inner-manager/subtitle-strategy-config"
	},
	{
		name : "translate_project_collection",
		router : "/inner-manager/translate-project-collection"
	},
	{
		name : "translate_project_collection_subtitle",
		router : "/inner-manager/translate-project-collection-subtitle"
	},
	{
		name : "project_collection_video",
		router : "/inner-manager/project-collection-video"
	},
	{
		name : "translate_project_collection_cast_info",
		router : "/inner-manager/translate-project-collection-cast-info"
	},
	{
		name : "offer_query",
		router : "/inner-manager/offer-query"
	},
	{
		name: "offer_detail",
		router: "/inner-manager/employee-offers"
	},
	{
		name: "offer_edit",
		router: "/inner-manager/employee-offers-edit"
	},
	{
		name : "intension-confirm",
		router : "/inner-manager/intension-confirm"
	},
	{
		name: "project-work-sheep",
		router: "/inner-manager/project-work-sheep"
	},
	{
		name: "work-sheep-detail",
		router: "/inner-manager/work-sheep-detail"
	},
	{
		name: "translate_project_manager",
		router: "/inner-manager/translate-project-manager"
	},
	//台繁词表
	{
		name : "tw_tip_word",
		router : "/inner-manager/tw-tip-word"
	},
	{
		name : "tw_tip_word_tool",
		router : "/inner-manager/tw-tip-word-tool"
	},
	//关键词下菜单
	{
		name : "propre_words",
		router : "/inner-manager/propre-words"
	},
	{
		name : "propre_words_album",
		router : "/inner-manager/propre-words-album"
	},
	{
		name : "propre_words_label",
		router : "/inner-manager/propre-words-label"
	},
	//合作方页面
	{
		name : "partner",
		router : "/cooperation",
		lang_key : "partner"
	},
	{
		name : "subtitle-feedback",
		router : false,
		lang_key : "subtitle-feedback"
	},
	{
		name : "cooperation-manager",
		router : false,
		lang_key : "cooperation-manager"
	},
	{
		name : "partner_page",
		router : "/cooperation/dashboard",
		lang_key : "partner_page"
	},
	{
		name : "staff_manage",
		router : "/cooperation/employee-manage",
		lang_key: "staff_manage"
	},
	{
		name : "staff_page",
		router : "/caption/my-employee",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1610416644651_779"
	},
	{
		name : "partner_translate_project",
		router : "/cooperation/translate-task",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1610416636032_686"
	},
	{
		name : "partner_translate_project_video",
		router : "/cooperation/translate-video",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1610416819567_190"
	},
	{
		name : "partner_video_screenshot",
		router : "/cooperation/translate-video-screenshot",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1621237806670_314"
	},
	{
		name: "partner-project-work-sheep",
		router: "/cooperation/project-work-sheep",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1616119353474_77"
	},
	{
		name: "partner-work-sheep-detail",
		router: "/cooperation/work-sheep-detail",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1616660820875_137"
	},
	//翻译任务相关页面（包括任务和工具页面）
	{
		name : "partner_translate_task",
		router : "/cooperation/video-task-list",
		lang_key : "ECHO_TRANSLATION_PLATFORM_1610416640093_368"
	},
	{
		name : "partner_video_mark_task",
		router : "/cooperation/video-mark-task-list"
	},
	{
		name : "video_task_preview",
		router : "/cooperation/video-task-preview",
		lang_key: "task-video-preview"
	},
	{
		name : "partner_quality_task",
		router : "/cooperation/quality-task-list",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1610416644176_172"
	},
	{
		name : "quality_task_preview",
		router : "/cooperation/quality-task-preview",
		lang_key: "task-video-preview"
	},
	{
		name : "video_quality_task_list",
		router : "/cooperation/video-quality-task-list",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1641888253974_165"
	},
	{
		name : "translate_sub_quality_dispute",
		router : "/caption/toolpage/translate-sub-quality-dispute",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1641888206091_587"
	},
	{
		name : "translate_quality_dispute",
		router : "/caption/toolpage/translate-quality-dispute",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1641888206091_587"
	},
	{
		name : "translate_quality_edit",
		router : "/caption/toolpage/translate-quality-edit",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1670825882592_532"
	},
	{
		name : "translate_sub_quality_edit",
		router : "/caption/toolpage/translate-sub-quality-edit",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1670825882592_532"
	},
	{
		name : "quality_quality_dispute",
		router : "/caption/toolpage/quality-quality-dispute",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1641888206091_587"
	},
	{
		name : "partner_translate_subtitle_edit",
		router : "/cooperation/translate-subtitle-edit",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1660890875290_373"
	},
	{
		name : "quality_page",
		router : "/caption/toolpage/quality-page",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1614756353849_315"
	},
	{
		name : "translate_page",
		router : "/caption/toolpage/translation-page",
		lang_key: "translate-page"
	},
	{
		name : "adjust_page",
		router : "/caption/toolpage/adjust-page",
		lang_key: "caption-adjust-page"
	},
	{
		name : "audit_page",
		router : "/caption/toolpage/verity-page",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1613990273059_268"
	},
	{
		name : "correct_page",
		router : "/caption/toolpage/corrector-page",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1614696778757_611"
	},
	{
		name : "product_page",
		router : "/caption/toolpage/product-page",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1614754441763_891"
	},
	{
		name : "mark_pretreatment_page",
		router : "/caption/toolpage/mark-pretreatment-page"
	},
	{
		name : "sub_task_mark_pretreatment_page",
		router : "/caption/toolpage/sub-task-mark-pretreatment-page"
	},
	//预览相关公共页面
	{
		name : "video_preview",
		router : "/collection/video-preview"
	},
	{
		name : "translate_amount",
		router : "/collection/translate-time"
	},
	{
		name : "error_amount",
		router : "/collection/translate-error"
	},
	//字幕匹配工具
	{
		name: "subtitle-matcher",
		router: "/thirdpard/subtitle-compare"
	},
	{
		name: "time_transfer_tools",
		router: "/thirdpard/time-transfer-tools"
	},
	//拍词工具
	{
		name: "patword_tool_setting",
		router: "/thirdpard/patword/setting"
	},
	{
		name: "patword_tool_patword",
		router: "/thirdpard/patword/patword"
	},
  // 字幕时间轴对齐工具
	{
		name: "subtitle_time_align_tools",
		router: "/thirdpard/subtitle-time-align-tools"
	},
	{
		name: "keyword_word_filte",
		router: "/keyword/word-filter",
	},
	{
		name: "tianji_translate_task_list",
		router: "/inner-manager/tianji-translate-task-list",
	},
	{
		name: "tianji_text_translate_task_list",
		router: "/inner-manager/tianji-text-translate-task-list",
	},
	{
		name: "partner_admin_tianji_translate_task_list",
		router: "/cooperation/tianji-translate-task-list",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1675230406860_961"
	},

  // 直播
  ...livePageName,
  //众包相关
  ...crowdsourcingPageName,
  // 文本翻译
  ...translateTextPageName,
  // 配音
  ...vcPageName
]