/**
 * 
 * @authors Wangfeiran (wangfeiran@qiyi.com)
 * @date    2019-05-05 14:20:34
 * @version 1.0.0
 * @desc 双向绑定的定制化input样式组件
 */

<template>
	<div>
		<input :name="filename" type="file" @change="fileSelect" :accept=accept @click="clear" ref="input">
	</div>
</template>

<script type="text/javascript">
	export default {
		name : "input-file",
		model : {
			prop : "file",
			event : "filechange"
		},
		props : {
			filename : {
				type : String,
				default : "file"
			},
			accept : {
				type : String,
				default : ""
			}
		},
		methods : {
			fileSelect (e){
				var files = e.target.files;
				if(files.length>0){
					this.$emit("filechange" , files[0])
				}
			},
			clear(){
				this.$refs.input.value = '';
				this.$emit("filechange" , null)
			}
		}
	};
</script>

<style lang="scss" scoped="">
	input[type="file"]::-webkit-file-upload-button {
		color: #fff;
	    background-color: #409eff;
	    border-color: #409eff;
	    font-size: 12px;
    	border-radius: 3px;
    	line-height: 2;
	    white-space: nowrap;
	    cursor: pointer;
	}
</style>