export default [
  {
		name : "live_translate_task",
		router : "/live/translate-task"
	},
	{
		name : "live_staff_page",
		router : "/live/my-employee"
	},
	{
		name : "live_manage",
		router : "/live"
	},
	{
		name : "live_shorthand_page",
		router : "/live/shorthand-page"
	},
	{
		name : "live_translate_page",
		router : "/live/translate-page"
	},
	{
		name : "live_patword_page",
		router : "/live/patword-page"
	},
	{
		name : "live_correct_page",
		router : "/live/correct-page"
	}
]