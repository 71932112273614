<template>
	<div class="caption-video d-flex justify-center full-width">
		<div style="position:relative" :class="`${global.innerWidth > 1900 ? 'large' : 'normal'}`">
			<video id="video-1" class="video-js vjs-default-skin vjs-big-play-centered" crossOrigin="use-credentials" useCORS=true></video>

			<template v-for="caption in currentCaption">
				<!-- alignment==2 为下居中对齐，不支持pos -->
				<div v-if="caption.alignment==2" class="d-flex justify-center flex-column full-width caption-content" :style="`text-align : center;color : white ; position : absolute;bottom : ${caption.marginBottom}%;font-size : ${fontSize}px;z-index : 999`">
					<div v-for="(item , index) in caption.content" :key="index">
						{{item}}
					</div>
				</div>

				<template v-else>
					<div v-if="caption.alignment==1" class="caption-content" :style="`color : white ; position : absolute;left : ${caption.marginLeft}%;bottom : ${caption.marginBottom}%;font-size : ${fontSize}px;z-index : 999;text-align : left;`">
						<div v-for="(item , index) in caption.content" :key="index">
							{{item}}
						</div>
					</div>
					<div v-if="caption.alignment==3" class="caption-content" :style="`color : white ; position : absolute;right : ${100 -caption. marginLeft}%;bottom : ${caption.marginBottom}%;font-size : ${fontSize}px;z-index : 999;text-align : right;`">
						<div v-for="(item , index) in caption.content" :key="index">
							{{item}}
						</div>
					</div>
				</template>
			</template>			
		</div>		
	</div>
</template>
<style lang="scss" scoped>
	.caption-content{
		font-family : Helvetica;
		text-shadow : 1.5px 1.5px 0 black , 1.5px 1.5px 0 black;
	}

	.normal .caption-content {
		text-shadow : 0.5px 0.5px 0 black , 0.5px 0.5px 0 black;
	}
</style>
<script type="text/javascript">
	export default {
		name : "v-video",
		props : {
			caption : {
				type : Array,
				default : ()=>[]
			},
			startTime : {
				type : [String , Number],
				default : ""
			},
			url : {
				type : String,
				default : ""
			},
			translateLanguage : {
				type : Number,
				default : 0
			},
			styles : {
				type : Array,
				default : ()=>[]
			},
			type : {
				type : [Number , String],
				default : ""
			},
			//视频分辨率等相关信息
			videoInfo : {
				type : Object,
				default : ()=>{}
			}
		},
		data (){
			return {
				currentCaption : [],
				player : {},
				index : 0,
				marginBottom : 9.2,
				marginLeft : 9.2,
				//fontSize : 12.4,
				alignment : 2
			}
		},
		mounted (){
			const playType = "mp4" // mp4 or m3u8
			const defaultOptions = {
				videoId: 'video-1', 
				//播放器宽度，单位像素 
				width: window.innerWidth > 1900 ? '768' : '480', 
				//播放器高度，单位像素 
				height: "", 
				controlBar: { 
					fullscreenToggle : true, // 是否展示全屏按钮 
					volumeMenuButton: true, // 是否展示音量调节功能 
					//播放速率 
					playbackRateMenuButton: { 
						playbackRates: [0.5, 1.0, 1.5, 2.0] 
					},
				},
				controls: true, // 是否展示整个控制条
				autoplay: true, // 是否自动播放
				preload: 'auto',
				muted: false, // 是否静音 
				bigPlayButton: true // 不自动播放时是否在画面左上角展示一个大的播放按钮 
			}

			const m3u8Options = {
				m3u8Url: 'https://meta.video.iqiyi.com/20200312/fd/64/1813ac7639220b088025c00ae16eb74a.m3u8',
				//用来设置是预览流还是运营流，预览流为'preview',oss存储格式预览流为‘osspreview’，运营流为其他值 
				videoType: 'nopreview',
				//设置播放视频类型，mp4格式为 video/mp4， m3u8为‘application/m3u8’，默认为m3u8格式 
				type: 'application/m3u8', 
			}

			const videoOptions = {
				//videoType为osspreview时需要设置，其他情况可以不设置。
				token: 'oss-auth-v2/0a8ab1a14e79caf257b4ea3af9944d66',
				//对应素材的m3u8的url地址 
				m3u8Url: 'http://meta.video.iqiyi.com/20181119/5b/d4/f81e9555aa33ac24886efa622bd7e6cc.m3u8',
				//videoType为preview时为对应素材的f4v的url地址，videoType为‘osspreview’时为设置的代理地址，如果不需要通过代理访问，可以不设置。
				f4vUrl: undefined,
				//mp4播放地址	
				url: this.url,
				//用来设置是预览流还是运营流，预览流为'preview',oss存储格式预览流为‘osspreview’，运营流为其他值 
				videoType: 'native',
				//设置播放视频类型，mp4格式为 video/mp4， m3u8为‘application/m3u8’，默认为m3u8格式 
				type: 'video/mp4'
			};

			// 获取实例 
			let player = lvjs.playerRun({ ...defaultOptions, ...(playType === "m3u8" ? m3u8Options : videoOptions)}, success); 

			if(playType === "m3u8"){
				player.ready(()=>{
					let tech = player.getTech()
					let hls = tech.hls_
					hls.on('hlsManifestLoaded', function(type, data){
						try {
							let fragments = data.levels[0].details.fragments
							fragments.forEach(element => {
								element.url = element.url.replace('http:', '')
							});
						} catch (error) {
							console.warn(error.message);
						}
					})
				})
			}

			var that = this;
			function success (player) {
				console.log("播放器初始化完成了")
				let watermark = that.watermark();
				player.on('loadeddata', function() {
					that.$emit("playerInited" , player);
				})
				// 添加事件监听
				player.on('play', function() {
					console.log('开始播放')
				})
				player.on('timeupdate', function() {
					that.setCaption(player.currentTime())
					watermark.timeupdate()
					that.bus.$emit("videoPlayUpdate" , player.currentTime());
				})
			}

			this.player = player;

			this.$on("play" , startTime => {
				console.log(startTime);
			})
			that.$el.querySelector("video").addEventListener("contextmenu", event=> {
				if (document.all) {
					event.returnValue = false;
				} else {
					event.preventDefault();
				}
			})
		},
		methods : {
			watermark(){
				let that = this
				class WaterMask{
					constructor(){
						const { user= {}, partnerUserBelong= {}} = that.$store.state
						this.video = that.$el.querySelector('video');
						this.videoParent = this.video.parentNode
						this.userUid = user.uid || ''
						this.nickname = user.nickname || ''
						this.companyName = partnerUserBelong.name || ''
						this.detectionTime = 5000 //水印检测时间 单位ms
						this.timeupdateTime = 0;
						this.domRender()
						this.sizeObserver();
						this.resize()
					}
					domRender(){
						let { watermask, video, videoParent, userUid, nickname, companyName } = this;
						if(watermask){
							watermask.parentNode && watermask.parentNode.removeChild(watermask)
							watermask = null;
							this.watermask = null;
						}
						watermask = document.createElement('div')
						watermask.style.cssText="position: absolute;display:flex;visibility:visible;flex-direction: column;justify-content: flex-start;align-items: center;top:0;left:0;right:0;bottom:50px;color:rgba(255,0,0,.9);font-size:10px;font-weight: bold;user-select: none;pointer-events: none;overflow: hidden;"
						watermask.innerHTML=`
						<div class="watermask-warn" style="flex: 0 0 auto;font-size:1.2em;color: rgba(255,0,0,.9);padding-top:10px;visibility:visible;overflow: hidden;text-align:center;line-height:1.4;">本视频仅${nickname}（用户ID：${userUid}）可见<br />仅供翻译视频，擅自复制传播属于严重侵权行为</div>
						<ul class="watermask-list" style="display: flex;flex: 1 1 auto;flex-direction: column;justify-content: space-around;margin:0;padding:0;font-size:1.8em;">
							<li style="list-style: none;overflow: hidden;">${companyName}  ${userUid}  ${nickname}</li>
							<li style="list-style: none;overflow: hidden;">${companyName}  ${userUid}  ${nickname}</li>
							<li style="list-style: none;overflow: hidden;"></li>
						</ul>`
						videoParent.appendChild(watermask)
						this.watermask = watermask
						this.sizeObserver()
					}
					timeupdate(){
						let curTime = new Date().getTime();
						let detectionTime = this.detectionTime
						let timeupdateTime = this.timeupdateTime
						if(curTime - timeupdateTime >= detectionTime){
							this.timeupdateTime = curTime
							this.domRender();
						}							
					}
					sizeObserver(){
						let watermask = this.watermask;
						let width = this.videoParent.offsetWidth || 480
						let size = (10 * width)/480;
						watermask && (watermask.style.fontSize=`${size}px`)
					}
					resize(){
						window.addEventListener("resize",v=>{
							this.sizeObserver()
						})
					}
				}
				return new WaterMask()
			},
			getTime (str){
				return parseInt(str) * 0.001;
			},
			setCaption(time){
				let select = this.dCaption.filter(val => {
					return this.getTime(val.start) <= time && time <= this.getTime(val.end)
				})
				
				this.currentCaption = select.map(v => {
					let content = this.type === 0 ? v.originalDialogueContent : v.translateDialogueContent;
					//如果有pos先删除pos
					if(content && content.indexOf("{\\pos") !== -1){
						let index = content.indexOf("}");
						content = content.slice(index + 1);
					}

					if(!content)content = "";

					let caption = this.setStyle(v);
					caption.content = _.compact(content.split("\\N"));
					return caption;
				})
			},
			setStyle(row){
				let captionStyle = {
					alignment : row.style.alignment
				}

				//下中对齐单独处理
				//详见产品文档：http://wiki.qiyi.domain/pages/viewpage.action?pageId=618628036
				if(captionStyle.alignment == 2){
					captionStyle.marginBottom = (row.style.marginV/this.videoInfo.playY) * 100;
				}else{
					let content = this.type == 0 ? row.originalDialogueContent : row.translateDialogueContent;
					let pos_match = content.match(/pos\<(\d+),(\d+)\>/g);
					//有pos信息的优先处理,pos的x对应marginL（右对齐换算百分比后求剩余），pos的y对应marginV的反向百分比
					if(pos_match){
						let pos = pos_match[0];
						let position = pos.match(/\d+/g);
						captionStyle.marginBottom = 100 - (position[1]/this.videoInfo.playY) * 100;
						captionStyle.marginLeft = (position[0]/this.videoInfo.playX) * 100;
					}else{
						captionStyle.marginBottom = (row.style.marginV/this.videoInfo.playY) * 100;
						if(captionStyle.alignment == 1){
							captionStyle.marginLeft = (row.style.marginL/this.videoInfo.playX) * 100;
						}
						if(captionStyle.alignment == 3){
							captionStyle.marginLeft = 100 - (row.style.marginR/this.videoInfo.playX) * 100;
						}
					}
				}

				return captionStyle;
			},
			play(time){
				this.player.currentTime(this.getTime(time))
				this.player.play();
			}
		},
		computed : {
			dCaption(){
				let dStyles = _.fromPairs(this.styles.map(v => [v.id , v]));
				return this.caption.map(v => {
					let styleId = this.type === 0 ? v.originalStyleId : v.translateStyleId;
					v.style = dStyles[styleId];
					v.style = v.style ? v.style : {};
					return v;
				})
			},
			fontSize(){
				let fontSize = 0.0592 * (window.innerWidth > 1900 ? 768 : 480) * this.videoInfo.playY / this.videoInfo.playX;
				if(this.translateLanguage != 1){
					fontSize *= 0.7;
				}
				return fontSize;
			}
		},
		//销毁组件之前销毁当前player，否则无法再次正常初始化
		beforeDestroy (){
			if(this.player)this.player.dispose();
		}
	};
</script>