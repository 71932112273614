/**
 * 
 * @authors Wangfeiran (wangfeiran@qiyi.com)
 * @date    2019-07-12 19:30:02
 * @version 1.0.0
 * @desc 全局工具函数
 */
import _ from "lodash"
import utils from "./utils"
import format from "./format"
import router from "./router"
import sys from "./sys"

let handler = _.cloneDeep(utils)
handler.format = format;
handler.router = router;
handler.sys = sys;

export default handler
