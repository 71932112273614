/**
 * 
 * @authors wails (w86534309@gmail.com)
 * @date    2019-07-13 11:08:44
 * @version 1.0
 * @desc 路由完成后执行
 */

//eslint-disable-next-line
export default ((to , from) => {
    
})