/**
 * 
 * @authors wangfeiran@qiyi.com
 * @date    2020-12-28 14:39:22
 * @desc    微服务加载
 */

import {
  registerMicroApps,
  addGlobalUncaughtErrorHandler,
  start,
} from "qiankun";

// 子应用注册信息
import apps from "./apps";

const version = process.env.NODE_ENV === 'production' ? '202109061759' : Date.now();

/**
* 添加全局的未捕获异常处理器
*/
addGlobalUncaughtErrorHandler(event => {
	console.log(event)
	const message = event.message;
	//加载失败时提示
	if (message && message.includes("died in status LOADING_SOURCE_CODE")){
		console.error("子应用加载失败，请检查应用是否可运行")
	}
})

function frameLoad(microApps = [], props = {} , fun){
	let appconfig = process.env.NODE_ENV === 'local'  ?  apps : microApps

	appconfig = appconfig.map(v => {
		v.props = {
			...v.props,
			...props
		}

		return v;
	})
	
	/**
	 * 注册子应用
	 * 第一个参数 - 子应用的注册信息
	 * 第二个参数 - 全局生命周期钩子
	 */
	registerMicroApps(appconfig);
	if(fun){
		fun();
	}
	//start函数必须加参数strictStyleIsolation隔离css样式，否则import的样式会冲突
	start({
		sandbox: {
			strictStyleIsolation: false
		},
		prefetch: "all",
		/*fetch(url , ...args){
			url = url + (url.indexOf("?")!== -1 ? '&' : '?') + 'transv=' + version
			return fetch(url , ...args)
		}*/
	});
}
//导出 qiankun 的启动函数
export default frameLoad;