/**
 * 
 * @authors wails (w86534309@gmail.com)
 * @date    2019-07-13 09:36:59
 * @version 1.0
 * @desc 纯路由配置文件，模板动态加载
 * @自定义字段说明：{
    meta.requiresAuth ： 是否需要登录
    desc ： 该页面功能说明（中文）
 }
 */
import apps from '@/micro/apps';

let appNames = apps.map((v) => v.name).filter((v) => !!v);
export default [
	{
		path: '/login',
		name: 'login',
		component: () => import('@/components/login/Login.vue')
	},
	{
		path: '/',
		name: 'index',
		component: () => import('@/components/layout/Layout.vue'),
		meta: {
			requiresAuth: true
		},
		children: [
			{
				path: '/',
				name: 'welcome',
				desc: '默认进入首页欢迎页面',
				component: () => import('@/components/Index.vue'),
				meta: {
					requiresAuth: false
				}
			},
			{
				path: '/errors/403',
				name: 'Forbidden',
				component: () => import('@/components/errors/Forbidden.vue'),
				meta: {
					requiresAuth: false
				}
			},
			{
				path: '/keyword',
				name: 'keyword',
				desc: '关键词相关页面',
				component: () => import(/* webpackChunkName: "components" */ '@/components/layout/IndexPage.vue'),
				children: [
					{
						path: '',
						redirect: 'keyword-special-word-list'
					},
					{
						path: 'special-word-list',
						name: 'keyword-special-word-list',
						desc: '专有词表',
						component: () =>
							import(/* webpackChunkName: "components" */ '@/components/keyword/SpecialWordList.vue')
					},
					{
						path: 'special-word-detail',
						name: 'keyword-special-word-detail',
						desc: '词表详情页',
						component: () =>
							import(/* webpackChunkName: "components" */ '@/components/keyword/SpecialWordDetail.vue')
					},
					{
						path: 'word-filter',
						name: 'keyword-word-filter',
						desc: '过滤词表',
						component: () =>
							import(/* webpackChunkName: "components" */ '@/components/keyword/WordFilter.vue')
					}
				]
			},
			{
				path: '/thirdpard/subtitle-compare',
				name: 'thirdpard-subtitle-compare',
				component: () =>
					import(/* webpackChunkName: "components" */ '@/components/third_pard/SubtitleCompare.vue'),
				meta: {
					requiresAuth: true,
					thirdPard: false
				}
			},
			{
				path: '/thirdpard/patword',
				name: 'third-pard-patword',
				component: () => import(/* webpackChunkName: "thirdpartPatword" */ '@/components/layout/IndexPage.vue'),
				children: [
					{
						path: 'setting',
						name: 'third-pard-patword-setting',
						component: () =>
							import(/* webpackChunkName: "thirdpartPatword" */ '@/components/third_pard/patword/Setting.vue')
					},
					{
						path: 'patword',
						name: 'third-pard-patword-patword',
						component: () =>
							import(/* webpackChunkName: "thirdpartPatword" */ '@/components/third_pard/patword/Patword.vue')
					}
				]
			},
			{
				path: '/thirdpard/subtitle-time-align-tools',
				name: 'thirdpard-subtitle-time-align-tools',
				component: () =>
					import(/* webpackChunkName: "thirdpartPatword" */ '@/components/third_pard/SubtitleTimeAlignmentTools.vue'),
				meta: {
					requiresAuth: false
				}
			},
			{
				path: '/thirdpard/time-transfer-tools',
				name: 'thirdpard-time-transfer-tools',
				component: () =>
					import(/* webpackChunkName: "thirdpartPatword" */ '@/components/third_pard/TimeTransferTools.vue'),
				meta: {
					requiresAuth: false
				}
			},
			{
				path: '/live',
				component: () => import('@/components/layout/IndexPage.vue'),
				children: [
					{
						path: 'translate-task',
						name: 'live-translate-task',
						desc: '直播翻译管理页面',
						component: () =>
							import(/* webpackChunkName: "live-components" */ '@/components/live/TranslateTask.vue')
					},
					{
						path: 'my-employee',
						name: 'live-my-employee',
						desc: '直播我的页面',
						component: () =>
							import(/* webpackChunkName: "live-components" */ '@/components/live/MyEmployee.vue')
					},
					{
						path: 'shorthand-page',
						name: 'live-shorthand-page',
						desc: '直播速记页面',
						component: () =>
							import(/* webpackChunkName: "live-components" */ '@/components/live/ShorthandPage.vue')
					},
					{
						path: 'translate-page',
						name: 'live-translate-page',
						desc: '直播翻译页面',
						component: () =>
							import(/* webpackChunkName: "live-components" */ '@/components/live/TranslatePage.vue')
					},
					{
						path: 'patword-page',
						name: 'live-patword-page',
						desc: '直播拍词页面',
						component: () =>
							import(/* webpackChunkName: "live-components" */ '@/components/live/PatWord.vue')
					},
					{
						path: 'correct-page',
						name: 'live-correct-page',
						desc: '直播校对页面',
						component: () =>
							import(/* webpackChunkName: "live-components" */ '@/components/live/CorrectPage.vue')
					}
				],
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '*',
				name: 'default-view',
				component: () => import('@/components/layout/SubView.vue'),
				meta: {
					requiresAuth: true
				}
			}
		]
	}
];
