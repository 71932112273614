/**
 * 
 * @authors Wangfeiran (wangfeiran@qiyi.com)
 * @date    2020-06-04 15:35:31
 * @version 1.0
 * @desc 注册vue公共指令
 * 自定义指令参考：https: //cn.vuejs.org/v2/guide/custom-directive.html
 */

import Vue from 'vue'
import store from '@/store/'
//复制到剪切板组件
import VueClipboard from 'vue-clipboard2'
import _ from "lodash"

Vue.use(VueClipboard)
//实现一个Vue变量实体，实现内部功能
let vue = new Vue();
/* 
 *  注册一个全局自定义指令 `v-haspermit`做控件的权限控制
 *  binding.value必须是个表达式，否则无法获取value，例如v-haspermit="`corpus-operation`"而不能写成v-haspermit="corpus-operation"
 *  该命令不能作用在template上，两种删除方法都无法生效
 */
Vue.directive('haspermit', {
	//必须在插入之后进行处理，否则无法移除
    inserted :  (el , binding , vNode)=>{
        let black = true;
        //判断是否在黑名单中，如果不在黑名单里则不屏蔽
        if(binding.value){
            var item = store.state.blackItem.filter(val=>{
                return val.name == binding.value
            })
            black = item.length !== 0
        }
        el.p = el.parentNode;//记录当前父节点，恢复时使用
        if(black){
            if(el.parentNode)el.parentNode.removeChild(el); //移除掉当前控件
            vNode.pNode = el.parentNode;
            vNode.data.hook?.destroy && vNode.data.hook.destroy(vNode);//同时移除虚拟node节点，针对el-table-column这种非直接渲染html的组件
        }
    },
    update :  (el , binding , vNode)=>{
        let black = true;
        //判断是否在黑名单中，如果不在黑名单里则不屏蔽
        if(binding.value){
            var item = store.state.blackItem.filter(val=>{
                return val.name == binding.value
            })
            black = item.length !== 0
        }
        
        if(black){
            if(el.parentNode)el.parentNode.removeChild(el); //移除掉当前控件
            vNode.data.hook?.destroy && vNode.data.hook.destroy(vNode);//同时移除虚拟node节点，针对el-table-column这种非直接渲染html的组件
        }else{
            if(el.p && !el.parentNode)el.p.append(el); //恢复当前控件
        }
    }
})

/*
*  全局自定义指令`v-copy`复制指定内容
*/
Vue.directive('copy' , {
	bind : (el , binding) => {
		el.nodeTextContent = binding.value;
		el.addEventListener('click', e => {
			//停止冒泡
            e.stopPropagation();
            //调用vue-clipboard2复制传递内容
            vue.$copyText(el.nodeTextContent).then(() => {
				vue.$message("复制成功")
			}).catch(e=>{
				console.log(e);
			})
        })
	},
	update : (el , binding) => {
		//节点更新时需要更新copy的值，否则会缓存第一个节点的值
		el.nodeTextContent = binding.value;
	}
})

/*
*  全局自定义指令`v-debounce`为元素绑定事件，并添加防抖函数效果
*/
Vue.directive("debounce" , {
    bind : (el , binding ,vNode) => {
        let event = binding.arg;
        let fun = binding.value;
        let time = el.attributes.time.value || 1000

        el.addEventListener(event , _.debounce(fun , time))
    }
})

/*
*  全局自定义指令`v-throttle`为元素绑定事件，并添加节流函数效果
*/
Vue.directive("throttle" , {
    bind : (el , binding ,vNode) => {
        let event = binding.arg;
        let fun = binding.value;
        let time = el.attributes.time.value || 1000

        el.addEventListener(event , _.throttle(fun , time))
    }
})