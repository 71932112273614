<template>
	<fragment>
		<el-select ref="input" v-model="select" class="input-edit" type="text" :disabled="disabled">
			<el-option
			v-for="(item , index) in list"
			:key="index"
			:value="item[vKey]"
			:label="item[dKey]">

			</el-option>
		</el-select>
		
	</fragment>
</template>
<style lang="scss" scoped="">
	.input-read{
		width : 100%;
		height : 15px;
	}
</style>
<script type="text/javascript">
	export default {
		name : "select-edit",
		props : {
			list : {
				type : Array,
				default : ()=>{
					return []
				}
			},
			value : {
				type : [Number , Object , String],
				default : ""
			},
			dKey : {
				type : String,
				default : ""
			},
			vKey : {
				type : String,
				default : ""
			},
			disabled : {
				type : Boolean,
				default : false
			}
		},
		data (){
			return {
				select : _.cloneDeep(this.value),
				edit : false,
				selectAbled : false
			}
		},
		computed : {
			displayName (){
				return _.fromPairs(this.list.map(v => [v[this.vKey] , v[this.dKey]]))[this.select]
			}
		},
		methods : {
			blur (){
				//延时500ms执行，避免el-select下拉箭头点击后导致的误操作blur
				setTimeout(() => {
					if(!this.selectAbled){
						this.edit = false;
					}
				} , 0.5 * 1000);
			},
			show(){
				if(this.disabled)return false;
				this.edit = true;
				this.$nextTick(()=>{
					this.$refs.input.focus();
				})
			},
			optionVisible(val){
				this.selectAbled = val;
				this.blur();
			}
		},
		watch : {
			select (val){
				if(this.value != this.select)this.$emit("input" , val);
			},
			value(){
				this.select = _.cloneDeep(this.value);
			}
		},
	};
</script>