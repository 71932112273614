<template>
	<div class="palyer">
		<video class="video-js" v-contextmenu="false"></video>
	</div>		
</template>
<style lang="scss">
.palyer{
	position: relative;
}
.video-js .vjs-time-tooltip{
	white-space: nowrap;
}
</style>
<script type="text/javascript">
	export default {
		name : "Player",
		props : {
			options:{
				type: Object
			},
			url : {
				type : String
			},
			hlsBack: {
				type: Function
			},
			// 是否有鼠标右键， 默认没有
			contextmenu: {
				type: Boolean,
				default: false
			},
			type : {
				type : String,
				validator(value){
					return ['m3u8', 'mp4', 'f4v'].includes(value) ? true : false
				},
				default: 'mp4'
			}
		},
		data (){
			return {
				player : null,
				_url: '',
				hlsManifestLoaded: null
			}
		},
		directives: {
			contextmenu: {
				bind(dom, value, vnod){
					// console.log('bind', dom, value, vnod)
				},
				inserted(dom, value, vnode){
					// console.log("inserted", dom, value, vnode)
				}
			}
		},
		watch : {
			options(value){
				this._options= value
				this.play()
			},
			url(value){
				this._url= value
				this.play()
			}			
		},
		mounted (){
			console.log("%c当前播放器的版本%c" + lvjs.VERSION + "%c", "color: #fff; background: #5f5f5f;padding:3px 6px;border-radius:6px 0 0 6px", "color: #fff; background: #4bc729;padding:3px 6px;border-radius:0 6px 6px 0", "")
			this._url= this.url
			this._options= this.options
			this.playerInit()
		},
		methods: {
			play(){
				const { player, playerInit, _url } = this
				if(!player){
					playerInit()
				}else{
					player.src(_url)
				}
			},
			playerInit(){
				const that= this
				const { _url , _options}= this
				const videoDom= this.$el.querySelector("video")
				// videoType为nopreview和native的区别
				// nopreview： 针对运营流，m3u8里面的具体ts地址是不带防盗链参数，前端js自己加
				// native： 针对外网，m3u8里面的具体ts地址默认后端会加上，前端不用管
				const baseOptions= {
					videoId: videoDom,
					controlBar: { 
						fullscreenToggle : true, // 是否展示全屏按钮 
						volumeMenuButton: true, // 是否展示音量调节功能 
						//播放速率 
						playbackRateMenuButton: { 
							playbackRates: [0.5, 1.0, 1.5, 2.0] 
						},
					},
					fluid: true,
					controls: true, // 是否展示整个控制条
					autoplay: true, // 是否自动播放
					preload: 'auto',
					muted: false, // 是否静音 
					bigPlayButton: true // 不自动播放时是否在画面左上角展示一个大的播放按钮 
				}
				const osspreview_m3u8Options = { 
					m3u8Url: _url,
					videoType: 'osspreview',
					type: 'application/m3u8', 
					hlsjs: {
						hlsConfig: {
							debug: false,
							headers: {
								withCredentials: true
							}
						}
					},
					height: 600
				}
				
				const mp4Options = {
					url: _url,
					videoType: 'native',
					type: 'video/mp4'
				};

				const toPalyOptions= {
					...(this.type==="m3u8" ? osspreview_m3u8Options : {}), 
					...(this.type==="mp4" ? mp4Options : {}), 
					...baseOptions, 
					..._options
				}

				let player = lvjs.playerRun(toPalyOptions, (player)=> {

					player.on('error', function() {
						that.$emit("error" , player.error()); 
					})

					player.on('loadedmetadata', function() {
						that.$emit("inited" , this); 
						console.log("can playing")
					})

					this.disabledContextmenu()
				}); 

				if(this.type==="m3u8"){
					player.ready(()=>{  
						let tech = player.getTech()
						let hls = tech.hls_
						this.hlsManifestLoaded = function(type, data){
							console.log('hlsManifestLoaded')
							try {
								let fragments = data.levels[0].details.fragments
								fragments.forEach(element => {
									if(that.hlsBack){
										element.url= that.hlsBack(element.url)
									}
								});
							} catch (error) {
								console.warn(error.message);
							}
						}
						hls.on('hlsManifestLoaded', this.hlsManifestLoaded)
					})
				}
				this.player = player;
			},
			dispose(){
				if(this.player){
					let tech = this.player.getTech()
					let hls = tech.hls_
					hls.off('hlsManifestLoaded', this.hlsManifestLoaded)
					this.player.dispose && this.player.dispose();
					this.player= null
				}
			},
			disabledContextmenu(){
				if(this.contextmenu) return false
				const video= this.$el.querySelector("video")
				video && video.addEventListener("contextmenu", event=> {
					if (document.all) {
						event.returnValue = false;
					} else {
						event.preventDefault();
					}
				})
			}
		},  
		destroyed(){
			this.dispose()
		}
	};
</script>