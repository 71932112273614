/**
 * 
 * @authors Wangfeiran (wangfeiran@qiyi.com)
 * @date    2019-09-16 15:24:31
 * @version 1.0.0
 * @desc 格式化table时统一的format
 */
import utils from "./utils"
import store from "@/store/"
import _ from "lodash"

const format = {
	undo(row , column , cellValue , index){
		if(_.isNull(cellValue) || _.isUndefined(cellValue)){
			return "-"
		}else{
			return cellValue
		}
	},
	date (row, column, cellValue, index){
		return utils.DateFormat(cellValue);
	},
	time (row, column, cellValue, index){
		return utils.TimeFormat(cellValue , 2);
	},
	translateStatus(row, column, cellValue, index){
		return store.state.translateStatus[cellValue];
	},
	language(row , column , cellValue , index){
		if(_.isNull(cellValue) || _.isUndefined(cellValue)){
			return "-";
		}
		if(!(cellValue instanceof Array))cellValue = String(cellValue).split(",");

		let language =  store.state.language.filter(val => {
			return cellValue.includes(String(val.id)) || cellValue.includes(parseInt(val.id))
		})

		return language.map(val => val.name).toString();
	},
	mediaType(row , column , cellValue , index){
		return store.state.mediaType[cellValue];
	},
	translateRole(row , colum , cellValue , index){
		let translator = store.state.translateRole.filter(val => val.code == cellValue);
		return translator.length > 0 ? translator[0].name : "";
	},
	wordType (row , column , cellValue , index){
		return store.state.wordType[cellValue];
	},
	partner (row , column , cellValue , index){
		let partner = store.state.partner.filter(val => {return val.id == cellValue});
		if(partner.length > 0)return partner[0].name;
		return "";
	},
	qualityDimension(row , column , cellValue , index){
		return store.state.qualityDimension[cellValue];
	},
	qualityStatus(row , column , cellValue , index){
		return store.state.qualityStatus[cellValue];
	},
	videoResourceType(row , column , cellValue , index){
		let videoResourceType = store.state.videoResourceType.filter( v => v.code == cellValue);
		return videoResourceType.length > 0 ? videoResourceType[0].name : "";
	},
	videoTranslateType(row , column , cellValue , index){
		let videoTranslateType = store.state.videoTranslateType.filter( v => v.code == cellValue);
		return videoTranslateType.length > 0 ? videoTranslateType[0].name : "";
	},
	translateType(row , column , cellValue , index){
		let type = store.state.translateType.filter(val => val.code == cellValue);
		return type.length > 0 ? type[0].name : "";
	},
	subtitleProductStatus(row , column , cellValue , index){
		let status = store.state.subtitleProductStatus;
		return _.fromPairs(status.map(v => [v.code , v.name]))[cellValue];
	},
	crowdSourcingPlatform(row , column , cellValue , index){
		let platform = store.state.crowdSourcingPlatform;
		return _.fromPairs(platform.map(v => [v.index , v.displayName]))[cellValue];
	},
	crowdSourcingSubtitleCheckStatus(row , column , cellValue , index){
		let platform = store.state.crowdSourcingSubtitleCheckStatus;
		return _.fromPairs(platform.map(v => [v.index , v.displayName]))[cellValue];
	},
	crowdSourcingProjectCheckStatus(row , column , cellValue , index){
		let platform = store.state.crowdSourcingProjectCheckStatus;
		return _.fromPairs(platform.map(v => [v.index , v.displayName]))[cellValue];
	},
	subtitleCheckTaskStatus(row , column , cellValue , index){
		let status = store.state.subtitleCheckTaskStatus;
		return status[cellValue];
	},
	subtitleCheckStatus(row , column , cellValue , index){
		let status = store.state.subtitleCheckStatus;
		return status[cellValue];
	},
	contentTypeInTranslateProjectCollectionEnum(row , column , cellValue , index){
		let enums = store.state.translateProjectCollectionEnum.contentType;
		return _.fromPairs(enums.map(v => [v.code , v.name]))[cellValue]
	},
	categoryInTranslateProjectCollectionEnum(row , column , cellValue , index){
		let enums = store.state.translateProjectCollectionEnum.category;
		return _.fromPairs(enums.map(v => [v.code , v.name]))[cellValue]
	},
	levelInTranslateProjectCollectionEnum(row , column , cellValue , index){
		let enums = store.state.translateProjectCollectionEnum.level;
		return _.fromPairs(enums.map(v => [v.code , v.name]))[cellValue]
	},
	priceTemplate(row , column , cellValue, index){
		let templates = store.state.priceTemplate;
		return _.fromPairs(templates.map(v => [v.key , v.name]))[cellValue]
	}
}

export default format