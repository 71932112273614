export default [
	{
		name: 'text_translate_project',
		router: '/inner-manager/text-translate-project'
	},
	{
		name: 'text_translate_project_create',
		router: '/inner-manager/text-translate-project-create'
	},
	{
		name: 'text_translate_project_edit',
		router: '/inner-manager/text-translate-project-edit'
	},
	{
		name: 'text_translate_project_detail',
		router: '/inner-manager/text-translate-project-detail'
	},
	{
		name: 'text_translate_settlement_setting',
		router: '/inner-manager/text-translate-settlement-setting'
	},
	{
		name: 'text_translate_task',
		router: '/inner-manager/text-translate-task'
	},
	{
		name: 'text_translate_task_detail',
		router: '/inner-manager/text-translate-task-detail'
	},
	{
		name: 'text_translate_demand',
		router: '/inner-manager/text-translate-demand'
	},
	{
		name: 'text_translate_project_collection',
		router: '/inner-manager/text-translate-project-collection'
	},
	{
		name: 'text_translate_project_log',
		router: '/inner-manager/text-translate-project-log'
	},
	{
		name: 'cooperation_text_translate',
		router: false,
		lang_key: 'ECHO_TRANSLATION_PLATFORM_1625543591778_748'
	},
	{
		name: 'cooperation_text_translate_task',
		router: '/cooperation/text-translate-task',
		lang_key: 'ECHO_TRANSLATION_PLATFORM_1625543592378_797'
	},
	{
		name: 'cooperation_text_translate_task_detail',
		router: '/cooperation/text-translate-task-detail'
	},
	{
		name: 'cooperation_text_translate_task_recieve',
		router: '/cooperation/text-translate-task-recieve',
    lang_key: 'ECHO_TRANSLATION_PLATFORM_1631762170059_511'
	},
	{
		name: 'translator_text_task_recieve',
		router: '/caption/text-translate-task-recieve',
		lang_key: 'ECHO_TRANSLATION_PLATFORM_1632542236203_780'
	},
	{
		name: 'translator_text_task',
		router: '/caption/text-translate-task',
		lang_key: 'ECHO_TRANSLATION_PLATFORM_1625543592378_797'
	},
	{
		name: 'translator_text_task_detail',
		router: '/caption/text-translate-task-detail'
	}
];
