export default [
  {
		name : "crowdsourcing_subtitle_detail",
		router : "/crowd-sourcing/subtitle-delivery-list"
	},
	{
		name : "crowdsourcing_subtitle",
		router : "/crowd-sourcing/subtitle-list"
	},
	{
		name : "crowdsourcing_audit_project",
		router : "/crowd-sourcing/inner-audit-project"
	},
	{
		name : "partner_subtitle",
		router : "/crowd-sourcing/cooperation-project-list",
		lang_key : "partner_subtitle"
	},
	{
		name : "partner_audit_project",
		router : "/crowd-sourcing/cooperation-audit-project",
		lang_key: "partner_audit_project"
	},
	{
		name : "partner_audit_tool",
		router : "/caption/audit-tools",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1614145738846_17"
	},
	{
		name : "partner_volunteer_audit_tool",
		router : "/caption/volunteer-audit-tools",
		lang_key: "ECHO_TRANSLATION_PLATFORM_1614145738846_17"
	},
  {
		name : "crowdsourcing_volunteer_project",
		router : "/crowd-sourcing/volunteer-project-list",
		lang_key : "ECHO_TRANSLATION_PLATFORM_1617704577830_168"
	},
  {
		name : "crowdsourcing_volunteer_project_detail",
		router : "/crowd-sourcing/volunteer-project-detail",
		lang_key : "ECHO_TRANSLATION_PLATFORM_1616119231709_906"
	},
  {
		name : "crowdsourcing_volunteer_audit_assignment",
		router : "/crowd-sourcing/volunteer-audit-assignment-list",
		lang_key : "ECHO_TRANSLATION_PLATFORM_1610416955407_371"
	},
  {
		name : "crowdsourcing_volunteer_management",
		router : "/crowd-sourcing/volunteer-management-list",
		lang_key : "志愿者管理"
	}
]