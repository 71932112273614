/**
 * 
 * @authors Wangfeiran (wangfeiran@qiyi.com)
 * @date    2019-09-16 15:44:00
 * @version 1.0.0
 * @desc 系统信息相关函数
 */
import request from "sync-request"
import utils from "@/common/utils"

const sys = {
 	getArea(){
 		let area_cookie = utils.getCookie("iq_translate_area");
 		let result = {};
 		if(area_cookie !== ""){
 			try{
 				result = JSON.parse(area_cookie)
 			}catch(e){
 				result = this.getAreaImmediately()
 			}
 		}else{
 			result = this.getAreaImmediately()
 		}
 		utils.setCookie("iq_translate_area" , JSON.stringify(result) , 86400);
 		return result;
 		
 	},
 	getAreaImmediately(){
 		let data = request("GET" , process.env.VUE_APP_HTTP_PRE + "/api-intl-translate/operation-anonymous/core/location");
 		if(data.statusCode == 200){
 			let dataBody = {};
 			try{
		        dataBody = JSON.parse(data.getBody('utf8'))
		    }catch(e){
		        dataBody = data.getBody('utf8')
		    }
		    return dataBody;
 		}else{
 			return false;
 		}
 	},
    getMicroAppImmediately(){
        let data = request("GET" , (window.http_pre ? window.http_pre : process.env.VUE_APP_HTTP_PRE) + "/api-intl-translate-basic/operation-anonymous/micro-frontend-project/query-translate-app-main-detail");
        if(data.statusCode == 200){
             let dataBody = {};
            try{
                dataBody = JSON.parse(data.getBody('utf8'))
            }catch(e){
                dataBody = data.getBody('utf8')
            }
            try{
                return dataBody.data.subProjects;
            }catch(e){
                return [];
            }
            
         }else{
             return [];
         }
    }
 }

 export default sys