<template>
	<div class="input-datetime-group">
		<slot></slot>
	</div>
</template>

<script type="text/javascript">
	export default {
		name : "date-time-group"
	};
</script>

<style lang="scss">
	.input-datetime-group{
		display: flex;
		justify-content: center;
		.input-datetime{
			
		}
	}
</style>