/**
 * 
 * @authors Wangfeiran (wangfeiran@qiyi.com)
 * @date    2019-07-12 19:12:56
 * @version 1.0.0
 * @desc 公共组件安装（main.js加载，全局使用）
 */
import Video from "./Video.vue"
import Player from "./Player/ondemand"
import PermissionItem from "./PermissionItem.vue"
import EmployeeSuggest from "./EmployeeSuggest.vue"
import EmployeeSuggestTip from "./EmployeeSuggestTip.vue"
import DateTimeInput from "./DateTimeInput.vue"
import DateTimeGroup from "./DateTimeGroup.vue"
import InputEdit from "./InputEdit.vue"
import SelectEdit from "./SelectEdit.vue"
import Search from "./Search.vue"
import InputFile from "./InputFile.vue"

const componets = {
	Video,
	PermissionItem,
	EmployeeSuggest,
	EmployeeSuggestTip,
	DateTimeInput,
	DateTimeGroup,
	InputEdit,
	SelectEdit,
	Search,
	InputFile,
	Player
}
export default {
	install(Vue) {
		for (var k in componets) {
			let value = componets[k]
			Vue.component(value.name, value)
		}

	}
}

