<template>
	<div class="input-datetime">
		<div class="input-wrap">
			<span class="input d-flex align-center justify-center">
				{{timestamp}}
			</span>
			
			<!-- <input readonly type="text" class="input" v-model="timestamp"/> -->
		</div>
		<div class="value-change-trigger" v-if="!disabled">
			<span class="up" @click="add">加</span>
			<span class="down" @click="minus">减</span>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		name : "date-time-item",
		props : {
			value : {
				type : [Number , String],
				default : 0
			},
			max : {
				type : Number,
				default : 9
			},
			each : {
				type  :Number,
				default : 1
			},
			disabled : {
				type : Boolean,
				default : false
			}
		},
		data (){
			return {
				timestamp : _.isNaN(parseInt(this.value)) ? 0 : parseInt(this.value)
			}
		},
		methods : {
			add (){
				if(this.disabled) return false;

				if(this.timestamp + this.each < this.max)
					this.timestamp += this.each;
				else
					this.timestamp = 0;
			},
			minus (){
				if(this.disabled) return false;
				if(this.timestamp === 0){
					this.timestamp = this.timestamp - this.each + this.max;
				}else if(this.timestamp > this.each){
					this.timestamp -= this.each;
				}else{
					this.timestamp = 0;
				}
			}
		},
		watch : {
			timestamp(){
				console.log(this.timestamp);
				this.$emit("input" , this.timestamp);
			},
			value (val){
				if(val != this.timestamp){
					this.timestamp = _.isNaN(parseInt(this.value)) ? 0 : parseInt(this.value);
				}
			}
		}
	};
</script>

<style lang="scss">
	.input-datetime{
		&:hover {
			.value-change-trigger{
				display : inline-block
			}
		}
		position: relative;
		flex: 1 1 auto;
		margin-left:10px;
		&:first-child{
			margin-left:0;
		}
		.input{
			display : inline-block;
			width: 36px;
			height:35px;
			line-height: 35px;
			padding:6px 6px 6px 2px;
			&:hover{
				border-color: #C0C4CC;
			}
			&:focus{
				border-color: #3f51b5
			}
		}
		.value-change-trigger{
			display : none;
			position: absolute;
			width:16px;
			height: 26px;
			top:50%;
			margin-top:-13px;
			right: 50%;
			margin-right:-4px;
		}
		.up,
		.down{
			position: absolute;
			top:0;
			right: 0;
			display: block;
			width:0;
			height: 0;
			line-height: 0;
			border-left-color: transparent;
			border-right-color: transparent;
			border-width: 10px 6px;
			border-style: solid;
			text-indent: -999px;
			cursor: pointer;
			overflow: hidden;
		}
		.up{
			top:-15px;
			border-top-color: transparent;
			border-bottom-color: #c3c3c3;
			&:hover{
				border-bottom-color: #333;
			}
		}
		.down{
			top:auto;
			bottom:-15px;
			border-bottom-color: transparent;
			border-top-color: #c3c3c3;
			&:hover{
				border-top-color: #333;
			}
		}

	}
</style>