<template>
	<fragment>
		<div class="edit" v-if="edit">
			<input v-model="input" class="input-edit" type="text" @blur="blur"/>

			<span class="input-length" v-if="maxLength > 0">最长字符数：{{maxLength}}</span>
		</div>
		

		<div v-else @click='show' class="input-read">
			{{input}}
		</div>
		
	</fragment>
</template>

<script type="text/javascript">
	export default {
		name : "input-edit",
		props : {
			value : {
				type : String,
				default : ""
			},
			disabled : {
				type : Boolean,
				default : false
			}
		},
		data (){
			return {
				input : this.value,
				edit : false
			}
		},
		watch : {
			input (val){
				this.$emit("input" , val);
			},
			value(val){
				if(this.value != this.input){
					this.input = this.value;
				}
			}
		},
		computed : {
			maxLength(){
				let content = this.input;
				if(!content)return 0;
				//如果有pos先删除pos
				if(content && content.indexOf("{\\pos") !== -1){
					let index = content.indexOf("}");
					content = content.slice(index + 1);
				}

				//根据\N换行分离出每一行,并计算每一行的字数
				let captions = content.split("\\N").map(v => v.length);

				//返回每一行字数里的最大值
				return _.max(captions)
			}
		},
		methods : {
			show(){
				if(this.disabled) return false;
				this.edit = true;
			},
			blur (){
				this.edit = false;
			}
		}
	};
</script>

<style lang="scss" scoped="">
	.edit{
		position : relative;
		.input-edit{
			width: 100%;
			background-color: transparent;
			border-style: none;
			color: inherit;
			&:focus{
				background: #f2f2f2;
			}
		}
		.input-length{
			position:absolute;
			bottom : -18px;
			right : 0
		}
	}
	

	.input-read{
		width : 100%;
		min-height : 15px;
	}
</style>