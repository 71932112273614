/**
 *
 * @authors wails (w86534309@gmail.com)
 * @date    2019-07-13 09:17:05
 * @version 1.0
 * @desc vuex数据存储
 */

import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import $$ from "axios";
import vuexMicroFrontends from "vuex-micro-frontends";
import utils from "@/common/utils/utils";

Vue.use(Vuex);

function addIndexFunc(enumMap) {
    enumMap.getList = (type) => {
        return enumMap[type] || [];
    };

    enumMap.getMap = (type) => {
        return enumMap.getList(type).reduce((pre, next) => {
            let code = next.code;
            if (code == null) {
                code = next.id;
            }
            pre[code] = next;
            return pre;
        }, {});
    };
    enumMap.getValue = (type, key) => {
        return enumMap.getMap(type)?.[key];
    };
    enumMap.getText = (type, key) => {
        return enumMap.getValue(type, key)?.name;
    };
    return enumMap;
}

let menuAllowChche = {};

export default new Vuex.Store({
    plugins: [vuexMicroFrontends.send()],
    state: {
        enumMap: {
            getText(type, key) {
                return key;
            },
            getValue() {
                return null;
            },
            getList() {
                return [];
            },
            getMap() {
                return {};
            },
        },
        lang: utils.getCookie("translateLang") || "zh_cn",
        user: {
            uname: "游客",
            nickname: "游客",
            thumbnail: "http://www.iqiyipic.com/common/fix/headicons/male-130.png",
        },
        allow: true,
        translateStatus: {
            1: "待分配",
            2: "原始字幕校对",
            3: "翻译中",
            4: "字幕制作中",
            5: "翻译完成",
            6: "字幕上传成功",
            7: "翻译终止",
            8: "字幕待确认",
        },
        translateRole: [],
        transcodeStatus: {
            1: "转码中",
            2: "转码成功",
            3: "转码失败",
            4: "转码超时",
        },
        mediaType: {
            1: "视频",
            2: "字幕",
            3: "台本",
        },
        wordType: {
            0: "其他",
            1: "角色名",
            2: "专有名词",
            3: "专有动词",
            4: "地名",
        },
        qualityStatus: {
            1: "待分配",
            2: "质检中",
            3: "质检完成",
            4: "质检中止",
            5: "问题反馈中",
        },
        qualityDimension: {
            1: "译员",
            2: "翻译项目",
            3: "翻译合作方",
            4: "定向质检",
        },
        translateType: [],
        language: [],
        partner: [],
        breadcrumb: [],
        blackItem: [],
        currentPage: null,
        menulist: [],
        videoResourceType: [],
        videoTranslateType: [],
        subtitleProductStatus: [],
        crowdSourcingPlatform: [],
        crowdSourcingSubtitleCheckStatus: [],
        crowdSourcingProjectCheckStatus: [],
        subtitleCheckTaskStatus: {
            0: "字幕检测中",
            1: "检测完成",
            2: "检测失败",
        },
        subtitleCheckStatus: {
            0: "待确认",
            1: "待处理",
            2: "已确认",
            3: "无需处理",
        },
        translateProjectCollectionEnum: {
            contentType: [],
            category: [],
            //"S+" , "S" , "A+" , "A", "B" , "无"
            level: [],
        },
        //品类
        translateCategoryList: [],
        //时效
        timeLimitList: [],
        crowdAuditDistributeStatus: [],
        priceTemplate: [
            {
                key: "offer-20-21",
                name: "20-21过渡版",
            },
        ],
        translateContentTypes: [],
        transcodingEmergency: [],
        qualityProduceErrorList: [],
        intentionConfirmResult: [],
        // 单句审核状态
        crowdSubtitleAuditStatusEnumList: [],
        // 分配状态
        volunteerProjectAssignStatusList: [],
        // 审核状态
        volunteerProjectAuditStatusList: [],
        // 项目状态
        volunteerProjectStatusList: [],
        // 子任务状态
        subTaskStatus: [],
        // 项目集类型
        translateBigProjectTypeList: [],
        // 文本类型
        textType: [],
        // 文本翻译任务状态
        textTranslateTaskStatus: [],
        textTranslateSourceStatus: [],
        textTranslateProjectProduceStatus: [],
        contentSources: [],
        middlegroundStrategyProjectType: [],
        middlegroundStrategyCopyrightType: [],
        partnerKind: [],

        // 配音相关
        dubModel: [],
        dubAgeRangeEnum: [],
        dubInternationalVoice: [],
        dubProjectStatus: [],
        dubPartnerTrackPermission: [],
        dubProjectFolderType: [],
        dubProjectChannel: [],
        dubTimbre: [],
        dubMakeAuditVideoStatus: [],
        dubAuditTaskStatus: [],
        dubMixStatus: [],
        dubPartnerList: [],
        sex: [],

        // 专有词
        wordStatus: [],
        wordType: [],
    },
    mutations: {
        setEnumMap(state, payload) {
            state.enumMap = payload;
        },
        setLanguage(state, payload) {
            state.language = payload.map(({ name, code, ext }) => ({ name, id: code, code: ext.code }));
        },
        setUser(state, payload) {
            let user = _.cloneDeep(payload);
            if (payload.thumbnail == "") user.thumbnail = "http://www.iqiyipic.com/common/fix/headicons/male-130.png";
            state.user = user;
        },
        setUserPartnerBelong(state, payload) {
            state.partnerUserBelong = payload;
        },
        setPartner(state, payload) {
            state.partner = payload;
        },
        updateMenu(state, payload) {
            state.menulist = payload;
        },
        clearBreadCrumb(state) {
            state.breadcrumb = [];
        },
        setBreadCrumb(state, payload) {
            if (!payload) return false;
            if (payload.length > 0) {
                state.breadcrumb = [];
                payload.forEach((val) => {
                    state.breadcrumb.push(val);
                });
            }
        },
        clearBlackItem(state) {
            state.blackItem = [];
        },
        setBlackItem(state, payload) {
            state.blackItem = payload;
        },
		setCurrentPage(state, payload) {
            state.currentPage = payload;
        },
        setTranslateRoles(state, payload) {
            state.translateRole = payload;
        },
        setVideoTranslateType(state, payload) {
            state.videoTranslateType = payload;
        },
        setVideoResourceType(state, payload) {
            state.videoResourceType = payload;
        },
        setTranslateType(state, payload) {
            state.translateType = payload;
        },
        setSubtitleProductStatus(state, payload) {
            state.subtitleProductStatus = payload;
        },
        setCrowdSourcingPlatform(state, payload) {
            state.crowdSourcingPlatform = payload;
        },
        setCrowdSourcingSubtitleCheckStatus(state, payload) {
            state.crowdSourcingSubtitleCheckStatus = payload;
        },
        setCrowdSourcingProjectCheckStatus(state, payload) {
            state.crowdSourcingProjectCheckStatus = payload;
        },
        setTranslateProjectCollectionEnum(state, payload) {
            state.translateProjectCollectionEnum.contentType = payload.translateProjectKindEnumList;
            state.translateProjectCollectionEnum.level = payload.translateBigProjectLevelEnumList;
            state.translateProjectCollectionEnum.category = payload.translateBigProjectChannelEnumVO;
        },
        setTimeLimitList(state, payload) {
            state.timeLimitList = payload;
        },
        setTranslateCategoryList(state, payload) {
            state.translateCategoryList = payload;
        },
        setTranslateContentTypes(state, payload) {
            state.translateContentTypes = payload;
        },
        setTranscodingEmergency(state, payload) {
            state.transcodingEmergency = payload;
        },
        setCrowdAuditDistributeStatus(state, payload) {
            state.crowdAuditDistributeStatus = payload;
        },
        setQualityProduceErrorList(state, payload) {
            state.qualityProduceErrorList = payload;
        },
        setIntentionConfirmResult(state, payload) {
            state.intentionConfirmResult = payload;
        },
        setCrowdSubtitleAuditStatusEnumList(state, payload) {
            state.crowdSubtitleAuditStatusEnumList = payload;
        },
        setVolunteerProjectAssignStatusList(state, payload) {
            state.volunteerProjectAssignStatusList = payload;
        },
        setVolunteerProjectAuditStatusList(state, payload) {
            state.volunteerProjectAuditStatusList = payload;
        },
        setVolunteerProjectStatusList(state, payload) {
            state.volunteerProjectStatusList = payload;
        },
        setSubTaskStatus(state, payload) {
            state.subTaskStatus = payload;
        },
        setTranslateBigProjectTypeList(state, payload) {
            state.translateBigProjectTypeList = payload;
        },
        setTextType(state, payload) {
            state.textType = payload;
        },
        setTextTranslateTaskStatus(state, payload) {
            state.textTranslateTaskStatus = payload;
        },
        setTextTranslateSourceStatus(state, payload) {
            state.textTranslateSourceStatus = payload;
        },
        setTextTranslateProjectProduceStatus(state, payload) {
            state.textTranslateProjectProduceStatus = payload;
        },
        setContentSources(state, payload) {
            state.contentSources = payload;
        },
        setMiddlegroundStrategyProjectType(state, payload) {
            state.middlegroundStrategyProjectType = payload;
        },
        setMiddlegroundStrategyCopyrightType(state, payload) {
            state.middlegroundStrategyCopyrightType = payload;
        },
        setPartnerKind(state, payload) {
            state.partnerKind = payload;
        },

        // 配音相关
        setDubModel(state, payload) {
            state.dubModel = payload;
        },
        setDubAgeRangeEnum(state, payload) {
            state.dubAgeRangeEnum = payload;
        },
        setDubInternationalVoice(state, payload) {
            state.dubInternationalVoice = payload;
        },
        setDubProjectStatus(state, payload) {
            state.dubProjectStatus = payload;
        },
        setDubPartnerTrackPermission(state, payload) {
            state.dubPartnerTrackPermission = payload;
        },
        setDubProjectFolderType(state, payload) {
            state.dubProjectFolderType = payload;
        },
        setDubProjectChannel(state, payload) {
            state.dubProjectChannel = payload;
        },
        setDubTimbre(state, payload) {
            state.dubTimbre = payload;
        },
        setDubMakeAuditVideoStatus(state, payload) {
            state.dubMakeAuditVideoStatus = payload;
        },
        setDubAuditTaskStatus(state, payload) {
            state.dubAuditTaskStatus = payload;
        },
        setDubMixStatus(state, payload) {
            state.dubMixStatus = payload;
        },
        setDubPartnerList(state, payload) {
            state.dubPartnerList = payload;
        },
        setSex(state, payload) {
            state.sex = payload;
        },
        setWordStatus(state, payload) {
            state.wordStatus = payload;
        },
        setWordType(state, payload) {
            state.wordType = payload;
        },
    },
    actions: {
        /**
         * 根据用户名获取该用户当前所属的翻译公司
         */
        getUserPartnerBelong({ commit }, { uname }) {
            var url = "/api-intl-translate/translate-partner/detail/username";
            $$.get(url, {
                params: {
                    userName: uname,
                },
            })
                .then((data) => {
                    commit("setUserPartnerBelong", data);
                })
                .catch((err) => {
                    console.error(err.message);
                });
        },
        getLanguage({ commit }) {
            var url = "/api-intl-translate/operation-anonymous/language";
            $$.get(url)
                .then((data) => {
                    commit("setLanguage", data);
                })
                .catch((err) => {
                    console.error(err.message);
                });
        },
        getUserInfo({ commit, dispatch }, payload) {
            $$.get("/api-intl-translate/operation-common/user-info")
                .then((data) => {
                    commit("setUser", data);
                    dispatch("getMenu");
                    // dispatch('getLanguage');
                    //dispatch("getPartner")
                    dispatch("getTranslateRoles");
                    dispatch("getVideoResourceType");
                    dispatch("getVideoTranslateType");
                    dispatch("getTranslateType");
                    dispatch("getUserPartnerBelong", data);
                    dispatch("getSubtitleProductStatus");
                    dispatch("getCrowdSourcingPlatform");
                    dispatch("getCrowdSourcingSubtitleCheckStatus");
                    dispatch("getCrowdSourcingProjectCheckStatus");

                    dispatch("getDictionary");
                })
                .catch((err) => {
                    commit("setUser", err);
                });
        },
        getBaseInfo({ commit, dispatch }, payload) {
            dispatch("getBreadCrumb", payload.path);
            dispatch("getBlackItem", {
                page: payload.path,
            });
        },
        getPartner({ commit }) {
            $$.get("/api-intl-translate/translate-partner/operation-anonymous/all-base-info").then((data) => {
                commit("setPartner", data);
            });
        },
        getMenu({ commit }) {
            var project = "translate",
                url = `/api-intl-translate/operation-common/menu-info?projectKey=${project}`;
            $$.get(url).then((data) => {
                commit("updateMenu", data.menu);
            });
        },
        getBreadCrumb({ commit }, payload) {
            if (payload == "") commit("clearBreadCrumb");
            else {
                var url = `/api-intl-translate/operation-common/breadcrumb-info?pageName=${payload}`;
                $$.get(url).then((data) => {
                    commit("setBreadCrumb", data.breadCrumb);
                });
            }
        },
        getBlackItem({ commit }, payload) {
            var url = `/api-intl-translate/operation-common/black-info`;
            $$.get(url, {
                params: {
                    pageName: payload.page,
                    roleIds: payload.roles,
                },
            })
                .then((data) => {
                    commit("setBlackItem", data.blackPageItems);
                    commit("setCurrentPage", payload.page);
                })
                .catch(() => {
                    commit("clearBlackItem");
                });
        },
        getTranslateRoles({ commit }) {
            var url = `/api-intl-translate/translate-partner/staff-type`;
            $$.get(url)
                .then((data) => {
                    commit("setTranslateRoles", data);
                })
                .catch(() => {});
        },
        getTranslateType({ commit }) {
            var url = `/api-intl-translate/translate-task/operation-anonymous/translate-type`;
            $$.get(url)
                .then((data) => {
                    commit("setTranslateType", data);
                })
                .catch(() => {});
        },
        async getMenuAllow(state, payload) {
            let chache = menuAllowChche[payload.menu];
            if (chache != null) {
                return chache;
            }
            let menuAllow = await $$.get(`/api-intl-translate/operation-common/menu-auth?projectKey=translate&menuKey=${payload.menu}`);
            menuAllowChche[payload.menu] = menuAllow.allow;
            return menuAllow.allow;
        },
        getVideoResourceType({ commit }) {
            var url = `/api-intl-translate/video/operation-anonymous/material-resource-type`;
            $$.get(url)
                .then((data) => {
                    commit("setVideoResourceType", data);
                })
                .catch(() => {});
        },
        getVideoTranslateType({ commit }) {
            var url = `/api-intl-translate/video/operation-anonymous/subtitle-translate-type`;
            $$.get(url)
                .then((data) => {
                    commit("setVideoTranslateType", data);
                })
                .catch(() => {});
        },
        //获取字幕投递生产状态
        getSubtitleProductStatus({ commit }) {
            let url = `/api-intl-translate/subtitle-product/operation-anonymous/subtitle-product-status`;
            $$.get(url)
                .then((data) => {
                    commit("setSubtitleProductStatus", data);
                })
                .catch(() => {});
        },
        getCrowdSourcingPlatform({ commit }) {
            let url = `/api-intl-translate-crowdsourcing-audit/type-enum/operation-anonymous/query-subtitle-delivery-platform-enum`;
            $$.get(url)
                .then((data) => {
                    commit("setCrowdSourcingPlatform", data);
                })
                .catch(() => {});
        },
        getCrowdSourcingSubtitleCheckStatus({ commit }) {
            let url = `/api-intl-translate-crowdsourcing-audit/type-enum/operation-anonymous/query-subtitle-audit-status-enum`;
            $$.get(url)
                .then((data) => {
                    commit("setCrowdSourcingSubtitleCheckStatus", data);
                })
                .catch(() => {});
        },
        getCrowdSourcingProjectCheckStatus({ commit }) {
            let url = `/api-intl-translate-crowdsourcing-audit/type-enum/operation-anonymous/query-audit-project-status-enum`;
            $$.get(url)
                .then((data) => {
                    commit("setCrowdSourcingProjectCheckStatus", data);
                })
                .catch(() => {});
        },
        //获取后端枚举集合
        //@todo 目前只有项目集的枚举，其他枚举待整合
        getDictionary({ commit }) {
            let url = `/api-intl-translate-basic/operation-anonymous/dictionary`;
            $$.get(url).then((data) => {
                let {
                    translateBigProjectChannelEnumVO,
                    translateBigProjectLevelEnumList,
                    translateProjectKindEnumList,
                    partnerList,
                    languageList,
                    translateCategoryList,
                    timeLimitList,
                    translateContentTypes,
                    transcodingEmergency,
                    crowdAuditDistributeStatus,
                    qualityProduceErrorList,
                    intentionConfirmResult,
                    crowdSubtitleAuditStatusEnumList,
                    volunteerProjectAssignStatusList,
                    volunteerProjectAuditStatusList,
                    volunteerProjectStatusList,
                    subTaskStatus,
                    translateBigProjectTypeList,
                    textType,
                    textTranslateTaskStatus,
                    textTranslateSourceStatus,
                    textTranslateProjectProduceStatus,
                    contentSources,
                    middlegroundStrategyProjectType,
                    middlegroundStrategyCopyrightType,
                    partnerKind,

                    dubModel = [],
                    dubAgeRangeEnum = [],
                    dubInternationalVoice = [],
                    dubProjectStatus = [],
                    dubPartnerTrackPermission = [],
                    dubProjectFolderType = [],
                    dubProjectChannel = [],
                    dubTimbre = [],
                    dubMakeAuditVideoStatus = [],
                    dubAuditTaskStatus = [],
                    dubMixStatus = [],
                    dubPartnerList = [],

                    sex = [],
                    wordStatus = [],
                    wordType = [],
                } = data;
                let translateProjectCollectionEnum = {
                    translateBigProjectChannelEnumVO,
                    translateBigProjectLevelEnumList,
                    translateProjectKindEnumList,
                };
                commit("setTranslateProjectCollectionEnum", translateProjectCollectionEnum);
                commit(
                    "setPartner",
                    partnerList.map((v) => {
                        if (!v.simpleName) v.simpleName = "";
                        return v;
                    }),
                );
                commit("setEnumMap", addIndexFunc(data));
                commit("setLanguage", languageList);
                commit("setTimeLimitList", timeLimitList);
                commit("setTranslateCategoryList", translateCategoryList);
                commit("setCrowdAuditDistributeStatus", crowdAuditDistributeStatus);
                commit("setQualityProduceErrorList", qualityProduceErrorList);
                commit("setTranslateContentTypes", translateContentTypes);
                commit("setTranscodingEmergency", transcodingEmergency);
                commit("setIntentionConfirmResult", intentionConfirmResult);
                commit("setCrowdSubtitleAuditStatusEnumList", crowdSubtitleAuditStatusEnumList);
                commit("setVolunteerProjectAssignStatusList", volunteerProjectAssignStatusList);
                commit("setVolunteerProjectAuditStatusList", volunteerProjectAuditStatusList);
                commit("setVolunteerProjectStatusList", volunteerProjectStatusList);
                commit("setSubTaskStatus", subTaskStatus);
                commit("setTranslateBigProjectTypeList", translateBigProjectTypeList);
                commit("setTextType", textType);
                commit("setTextTranslateTaskStatus", textTranslateTaskStatus);
                commit("setTextTranslateSourceStatus", textTranslateSourceStatus);
                commit("setTextTranslateProjectProduceStatus", textTranslateProjectProduceStatus);
                commit("setContentSources", contentSources);
                commit("setMiddlegroundStrategyProjectType", middlegroundStrategyProjectType);
                commit("setMiddlegroundStrategyCopyrightType", middlegroundStrategyCopyrightType);
                commit("setPartnerKind", partnerKind);

                commit("setDubModel", dubModel);
                commit("setDubAgeRangeEnum", dubAgeRangeEnum);
                commit("setDubInternationalVoice", dubInternationalVoice);
                commit("setDubProjectStatus", dubProjectStatus);
                commit("setDubPartnerTrackPermission", dubPartnerTrackPermission);
                commit("setDubProjectFolderType", dubProjectFolderType);
                commit("setDubProjectChannel", dubProjectChannel);
                commit("setDubTimbre", dubTimbre);
                commit("setDubMakeAuditVideoStatus", dubMakeAuditVideoStatus);
                commit("setDubAuditTaskStatus", dubAuditTaskStatus);
                commit("setDubMixStatus", dubMixStatus);
                commit("setDubPartnerList", dubPartnerList);
                commit("setSex", sex);

                commit("setWordStatus", wordStatus);
                commit("setWordType", wordType);
            });
        },
    },
});
