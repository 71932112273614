/**
 * 
 * @authors wangfeiran@qiyi.com
 * @date    2021-01-12 18:19:24
 * @version 1.0
 */

import axios from 'axios';
import utils from '@/common/utils';
import router from '@/router/';
import Vue from 'vue';
import { sendErrorLog } from '@/services/common';

const sendError = (response) => {
  if (process.env.NODE_ENV !== 'production') return
  const {
    config: {
      method,
      url,
      data,
      params
    },
    data: responseData,
    status
  } = response;
  if (url === 'https://intl.iqiyi.com/operation-external/front-log') return;
  sendErrorLog({
    url,
    httpMethod: method,
    responseCode: status,
    responseData: JSON.stringify(responseData),
    requestData: JSON.stringify(['get', 'delete'].includes(method) ? params : data)
  })
}

const firebaseSend = ({status, config, data}) => {
  if (process.env.NODE_ENV !== 'production') return;
  window.analyticsApplication.send({
    api_log_status: status,
    api_log_url: config.url,
    api_log_time: Date.now() - config.requestBeginTime,
    eventType: 'api_log'
  })
}

//获取当前用户地区，大陆访问iqiyi.com域名
let area = utils.sys.getArea();
let pre = process.env.VUE_APP_HTTP_PRE;
if (area.data == 'CN') {
	pre = process.env.VUE_APP_HTTP_PRE_INNER;
}

//配置域名开头
console.log(process.env.VUE_APP_HTTP_PRE);
axios.defaults.baseURL = pre;
axios.defaults.withCredentials = true;

//http request 拦截器 为全局添加参数
axios.interceptors.request.use((config) => {
	 // 检查请求地址是否包含 "/api-intl-translate/login"
	 if (!config.url.includes('/api-intl-translate/login')) {
		// 如果不包含，则添加 Opsession 头部
		config.headers['Opsession'] = utils.getCookie('OPSESSION');
	  }
  	config.requestBeginTime = Date.now()
	return config;
});

// http response 拦截器 全局拦截，抛出错误
axios.interceptors.response.use(
	(response) => {
		const {status, config, data} = response;
    firebaseSend({status, config})
		if (data.code === 0) {
			if (response.headers.opsession) {
				return response;
			} else {
				return data.data;
			}
		} else {
      sendError(response)
			return Promise.reject(data);
		}
	},
	(error) => {
		if (error.response) {
      const {config, response} = error;
			switch (response.status) {
				case 401:
					// 清除token信息并跳转到登录页面
					console.log(router.app.$route.name);
					if (router.app.$route.name !== 'login') {
						router.push({
							name: 'login',
							query: { type: 'logout', path: router.app.$route.fullPath || '/' }
						});
					}
					if (response.data.code == 401) {
						return Promise.reject({});
					}
        case 403:
          break;
        default:
          sendError(response)
          firebaseSend({status: response.status, config})
					break;
			}

			return Promise.reject(response.data); // 返回接口返回的错误信息
		} else {
			return Promise.reject({
				message: error.message
			});
		}
	}
);

//暴露分地区以后的域名前缀
Vue.prototype.http_pre = pre;
window.http_pre = pre;
console.log(window.http_pre)

export default axios;
