<template>
	<el-autocomplete
		v-model="nickname"
		:fetch-suggestions="getPartner"
		placeholder="请输入内容"
		@select="handleSelect"
		:value-key="displayKey"
		clearable
		:disabled="disabled"
	>
		<template slot-scope="{ item }">
		    <span :title="item[displayKey]">{{item[displayKey]}}</span>
		</template>
	</el-autocomplete>
</template>

<script type="text/javascript">
	export default {
		name : "employee-suggest",
		props : {
			value : {
				type : String,
				default : ""
			},
			staffType : {
				type : [Number , String],
				default : ""
			},
			originalLanguage : {
				type : [Number , String],
				default : ""
			},
			translateLanguage : {
				type : [Number , String],
				default : ""
			},
			workStatus : {
				type : Number, 
				default : 1
			},
			displayKey : {
				type : String,
				default : "staffNickname"
			},
			valueKey : {
				type : String,
				default : "staffUserName"
			},
			disabled : {
				type : Boolean,
				default : false
			}
		},
		data (){
			return {
				url : "/api-intl-translate/suggest/staff",
				nickname : "",
				username : ""
			}
		},
		computed : {
			params (){
				return {
					staffType : this.staffType,
					workStatus : this.workStatus,
					translateLanguage : this.translateLanguage,
					originalLanguage : this.originalLanguage
				}
			}
		},
		methods : {
			getPartner (key , cb){
				$$.get(this.url , {
					params : _.extend({
						staffNickname : key
					} , this.params)
				}).then(data => {
					cb(data)
				})
			},
			handleSelect(val){
				this.$emit("input" , val[this.valueKey])
			},
			init(){
				if(this.value && !this.nickname){
					$$.get(`/api-intl-translate/translate-partner-staff/base-info` , {
						params : _.extend({
							staffUserName : this.value
						} , this.params)
					}).then(data => {
						this.nickname = data.staffNickname;
					})
				}else if(!this.value){
					this.nickname = "";
				}
			}
		},
		watch : {
			nickname(val){
				if(!val){
					this.$emit("input" , null);
				}
			},
			value(){
				this.init();
			}
		},
		mounted(){
			this.init();
		}
	};
</script>