/**
 * 
 * @authors wangfeiran@qiyi.com
 * @date    2021-01-12 18:13:27
 * @version 1.0
 */

 import $$ from 'axios'
 import request from '@/plugin/request.js'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import twLocale from 'element-ui/lib/locale/lang/zh-TW'
import thLocale from 'element-ui/lib/locale/lang/th'
import idLocale from 'element-ui/lib/locale/lang/id'
import viLocale from 'element-ui/lib/locale/lang/vi'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import koLocale from 'element-ui/lib/locale/lang/ko'
import esLocale from 'element-ui/lib/locale/lang/es'
import vuetify from '@/plugin/vuetify';
import store from '@/store/'
import startQiankun from "@/micro"

async function getLang(router , store , App , Vue , VueI18n , ElementUI , axios){
	Vue.use(VueI18n) //一定要先引用，后使用，否则会报错

	let url = "/api-intl-translate-basic/operation-anonymous/corpus-package/query-echo-corpus";
	let langs = await $$.get(url , {
		timeout: 10 * 1000
	}).then(data => {
    localStorage.setItem('languageInfo', JSON.stringify(data))
    return data;
  }).catch(err => {
		// this.$message.error(err.message);
    const languageInfo = localStorage.getItem('languageInfo');
		return languageInfo ? JSON.parse(languageInfo) : [{
			langInfo: [
				{
					lang: "zh_cn"
				}
			]
		}];
	});

	if(langs.length > 0 ){
		let langInfo = langs[0].langInfo.map(v => v.lang);
		let langMap = {};
		langInfo.forEach(v => {
			langMap[v] = {};
			langs.forEach(lang => {
				langMap[v][lang.businessKey] = lang.langInfo.find(o=>o.lang == v).value || null;
			})
		})
		
		let message = {
			"zh_cn" : {
				...langMap.zh_cn,
				...zhLocale
			},
			"en_us" : {
				...langMap.en_us , 
				...enLocale
			},
			"zh_tw" : {
				...langMap.zh_tw,
				...twLocale
			},
			"th_th" : {
				...langMap.th_th,
				...thLocale
			},
			"ms_my" : {
				...langMap.id_id,
				...idLocale
			},
			"id_id" : {
				...langMap.id_id,
				...idLocale
			},
			"vi_vn": {
				...langMap.vi_vn,
				...viLocale
			},
			"ja" : {
				...langMap.ja,
				...jaLocale
			},
			"ko" : {
				...langMap.ko,
				...koLocale
			},
			"es_mx" : {
				...langMap.es_mx,
				...esLocale
			}
		}
		let lang = store.state.lang;
		
		const i18n = new VueI18n({
	        locale : lang,
	        messages : message,
			fallbackLocale: "zh_cn",
	    })
		console.log({
	        locale : lang,
	        messages : message
	    })
		Vue.use(ElementUI, {
		    i18n: (key, value) =>{
		    	return i18n.t(key, value)
		    } 
		})
		
		axios.defaults.headers = {
		    "Accept-Language" : "en-us"
		}

		new Vue({
			i18n,
			router,
			store,
			vuetify,
			render: h => h(App)
		}).$mount('#main-app')
		
		window.__subParams__ = {
			vue : Vue,
			ElementUI,
			i18n,
      		axios,
			request
		}
		/*startQiankun({
			vue : Vue,
			ElementUI,
			i18n
		} ,() => {
			
		});*/
	}
}

export default getLang;