/**
 * 
 * @authors Wangfeiran (wangfeiran@qiyi.com)
 * @date    2019-09-16 15:44:00
 * @version 1.0.0
 * @desc 基础函数
 */
import Vue from "vue"

const utils = {
	setCookie (name, value, expires, dm = location.hostname) {
	    var Then = new Date();
	    Then.setTime(Then.getTime() + expires*1000 );
	    document.cookie = name + "=" + value + "; expires=" + Then.toGMTString()+"; path=/;domain="+dm+";";
	},

	getCookie (name) {
	    var key = name + '='
	    var ca = document.cookie.split(';')
	    for (var i = 0; i < ca.length; i++) {
	      var c = ca[i].trim()
	      if (c.indexOf(key) === 0) return c.substring(key.length, c.length)
	    }
	    return ""
	},
	/**
		时间parse函数，type:0(需要毫秒) ， type:1(不需要毫秒) , type : 2(只需要分钟 + 秒)
	*/
	TimeFormat (time , type=0){
		if(_.isNaN(parseInt(time)))return time;
		//parseInt舍弃小数位
		time = parseInt(time);
		let hour = '',
			min = '',
			sec = '',
			micSec = '';
		//取余算毫秒
		micSec = this.timeNumFormat(time % 1000 , 2);
		//剩下需要计算的秒数
		time = Math.floor(time / 1000);

		//取余算秒
		sec = this.timeNumFormat(time % 60);
		//剩下需要计算的分钟数
		time = Math.floor(time / 60);

		if(type === 2){
			min = this.timeNumFormat(time);
			return `${min}:${sec}`;
		}
		//取余算分钟
		min = this.timeNumFormat(time % 60);
		//剩下需要计算的小时数
		hour = this.timeNumFormat(Math.floor(time / 60));

		let timeString = `${hour}:${min}:${sec}`;
		if(type === 0){
			timeString += `.${micSec}`
		}
		return timeString;
	},
	timeNumFormat (time , digits = 1){
		if(digits == 1){
			if(time < 10)time = "0" + time;
		}

		if(digits == 2){
			if(time < 10)time = "00" + time;
			else if(time < 100)time = "0" + time;
		}
		return time;
	},
  timeToSec(time) {
    const [hour = 0, min = 0, sec = 0] = time.split(':');
    return (Number(hour * 3600) + Number(min * 60) + Number(sec)) * 1000
  },
  stringToMSec(timeSting) {
    const [min = 0, sec = 0, msec = 0] = timeSting.split(':');
    return Number(min * 60000) + Number(sec * 1000) + Number(msec)
  },
	DateFormat (time,fmt = "yyyy-MM-dd hh:mm:ss"){
		// if(typeof time != "object")time = new Date(time);
		if(!time){
			return "";
		}
		if(typeof time == "number"){
			time = new Date(time);
		}
		var o = {
		  "M+": time.getMonth() + 1, //月份
		  "d+": time.getDate(), //日
		  "h+": time.getHours(), //小时
		  "m+": time.getMinutes(), //分
		  "s+": time.getSeconds(), //秒
		  "q+": Math.floor((time.getMonth() + 3) / 3), //季度
		  "S": time.getMilliseconds() //毫秒
		};
		if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (time.getFullYear() + "").substr(4 - RegExp.$1.length));
		for (var k in o)
		if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
		return fmt;
	},
	string2Timestamp(str , separator = ":"){
		let nums = str.split(separator);
		nums = nums.map(v => parseFloat(v));

		if(nums.length == 1){
			return nums[0] * 1000;
		}

		if(nums.length == 2){
			return ( nums[0] * 60 + nums[1] ) * 1000;
		}

		if(nums.length == 3){
			return ( nums[0] * 3600 + nums[1] * 60 + nums[2]) * 1000;
		}
	},
	funDownload (url, filename = "") {
		if(url.indexOf("http") !== 0){
			url = Vue.prototype.http_pre + url;
		}
		url = this.addParams(url , {
			t : new Date().getTime()
		})
		var eleLink = document.createElement('a');
		eleLink.download = filename;
		eleLink.target = "_blank";
		eleLink.style.display = 'none';
		// 字符内容转变成blob地址
		eleLink.href = url;
		// 触发点击
		document.body.appendChild(eleLink);
		eleLink.click();
		// 然后移除
		document.body.removeChild(eleLink);
	},
	contentDownload (url, filename = "") {
		var eleLink = document.createElement('a');
		eleLink.download = filename;
		eleLink.target = "_blank";
		eleLink.style.display = 'none';
		// 字符内容转变成blob地址
		eleLink.href = url;
		// 触发点击
		document.body.appendChild(eleLink);
		eleLink.click();
		// 然后移除
		document.body.removeChild(eleLink);
	},
	getTimeTodayRemain(){
		var now = new Date();
		return new Date(now.getTime() - (now.getHours()*3600 + now.getMinutes()*60 + now.getSeconds()) * 1000 );
	},
	deleteParams(data , key){
		let clone = _.cloneDeep(data);
		delete clone[key];
		data = _.cloneDeep(clone);
		return data;
	},
	addParams(url,params){
	    for(var k in params){
	        if(params[k] || params[k]===0)url = this.addParam(url,k,params[k]);
	    }
	    return url;
	},
	addParam(url,params,data){
	    if(url.indexOf("?")!==-1){
	        url += "&"+params+"="+data;
	    }else{
	        url += "?"+params+"="+data;
	    }
	    return url;
	},
	//比较对象，浅层比较
	compareObject(obj1 , obj2){
		let keys1 = _.keys(obj1) , keys2 = _.keys(obj2);
		let keys = keys1.length > keys2.length ? keys1 : keys2;

		let same = true;
		_.forEach(keys , key => {
			if(obj1[key]){
				if(obj2[key] && obj1[key] == obj2[key]){
					/** */
				}else {
					same = false;
				}
			}else{
				if(obj2[key]){
					same = false;
				}else{
					/** */
				}
			}
		})
		return same;
	},
	//base64文件上传
	fileUploadBase64(file , filename="file"){
		let url = `/api-intl-translate/file/upload`,
			params= {};
		params[filename] = this.convertBlobToFile(this.convertBase64UrlToBlob(file) , `screen-${Date.now()}.jpg`);
		console.log(params[filename])
		let data = this.getFormData(params)

		return $$.post(url , data).then(data => {
			return data;
		}).catch(err => {
			return false;
		})
	},
	//将base64的图片转成blob
	convertBase64UrlToBlob(urlData){
		var bytes=window.atob(urlData.split(',')[1]);        //去掉url的头，并转换为byte

		//处理异常,将ascii码小于0的转换为大于0
		var ab = new ArrayBuffer(bytes.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < bytes.length; i++) {
			ia[i] = bytes.charCodeAt(i);
		}

		return new Blob( [ab] , {type : 'image/png' , name: "test.jpg"});
	},
	//将blob转成file
	convertBlobToFile(blob , name=""){
		name = name || Date.now();
		return new File([blob] , name);
	},
	//数组转formdata
	getFormData (data){
		const {FormData} = require('sync-request');
		var formData = new FormData();
		for(var k in data){
			if(data[k] instanceof Array){
				data[k].forEach(v => {
					formData.append(`${k}[]` , v);
				})
			}else{
				formData.append(k , data[k]);
			}
		}

		return formData;
	}
}

export default utils
