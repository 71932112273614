<template>
	<el-autocomplete
		v-model="nickname"
		:fetch-suggestions="getPartner"
		placeholder="请输入内容"
		@select="handleSelect"
		:value-key="displayKey"
		clearable
		:disabled="disabled"
		popper-class="subtitle-suggest"
	>
		<template slot-scope="{ item }">
		    <div class="subtitle" v-if="!item.isTitle">
				<span class="subtitle-single original-language">{{global.utils.format.language(null , null , item.originalLanguageSet)}}</span>
				<span class="subtitle-single translate-language">{{global.utils.format.language(null , null , item.translateLanguageSet)}}</span>
				<span class="subtitle-single staff-nickname" :title="item.staffNickname">{{item.staffNickname}}</span>
			</div>

			<div v-else  @click.stop class="subtitle">
				<span class="subtitle-single original-language">{{item.originalLanguageSet}}</span>
				<span class="subtitle-single translate-language">{{item.translateLanguageSet}}</span>
				<span class="subtitle-single staff-nickname">{{item.staffNickname}}</span>
			</div>
		</template>
	</el-autocomplete>
</template>

<script type="text/javascript">
	//带tip提示的员工suggest组件
	export default{
		name : "employee-suggest-tip",
		props : {
			value : {
				type : String,
				default : ""
			},
			staffType : {
				type : [Number , String],
				default : ""
			},
			originalLanguage : {
				type : [Number , String , Array],
				default : ""
			},
			translateLanguage : {
				type : [Number , String , Array],
				default : ""
			},
			workStatus : {
				type : Number, 
				default : 1
			},
			displayKey : {
				type : String,
				default : "staffNickname"
			},
			valueKey : {
				type : String,
				default : "staffUserName"
			},
			disabled : {
				type : Boolean,
				default : false
			}
		},
		data (){
			return {
				url : "/api-intl-translate/suggest/staff",
				nickname : "",
				username : ""
			}
		},
		computed : {
			params (){
				return {
					staffType : this.staffType,
					workStatus : this.workStatus,
					translateLanguage : this.translateLanguage.toString(),
					originalLanguage : this.originalLanguage.toString()
				}
			}
		},
		methods : {
			getPartner (key , cb){
				$$.get(this.url , {
					params : _.extend({
						staffNickname : key
					} , this.params)
				}).then(data => {
					let title = [{
						originalLanguageSet : "原始语言",
						translateLanguageSet : "翻译语言",
						staffNickname : "用户名称",
						staffUserName : "语种",
						isTitle : true
					}]

					data = _.concat(title , data);

					cb(data)
				})
			},
			handleSelect(val){
				this.$emit("input" , val[this.valueKey])
			},
			init(){
				if(this.value && !this.nickname){
					$$.get(`/api-intl-translate/translate-partner-staff/base-info` , {
						params : _.extend({
							staffUserName : this.value
						} , this.params)
					}).then(data => {
						this.nickname = data.staffNickname;
					})
				}else if(!this.value){
					this.nickname = "";
				}
			}
		},
		watch : {
			nickname(val){
				if(!val){
					this.$emit("input" , null);
				}
			},
			value(){
				this.init();
			}
		},
		mounted(){
			this.init();
		}
	};
</script>

<style lang="scss">
	.subtitle-suggest{
		width : 400px !important;
		.subtitle-single{
			overflow : hidden;
			vertical-align:middle;
			white-space: nowrap;
			text-overflow: ellipsis;
			display : inline-block;
			&.original-language{
				width : 125px;
			}
			&.translate-language{
				width : 125px;
			}
			&.staff-nickname{
				width : 100px;
			}
		}
	}
</style>