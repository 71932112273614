/**
 * 
 * @authors Wangfeiran (wangfeiran@qiyi.com)
 * @date    2019-10-18 16:15:28
 * @version 1.0.0
 * @desc 路由正反解析
 */
import pageNames from "@/config/page_name"

const router =  {
	getRouter (name){
		let router = pageNames.filter(val => {
          return val.name == name
        })
        if(router.length > 0)return router[0].router;
        else return false;
	},
	getName (path){
		let router = pageNames.filter(val => {
          return val.router == path
        })
        if(router.length > 0)return router[0].name;
        else return "";
	},
  getKey (name){
    let router = pageNames.filter(val => {
          return val.name == name
        })
        if(router.length > 0)return router[0].lang_key;
        else return false;
  }
}

export default router;
