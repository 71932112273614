import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from "@/plugin/http"
import request from "@/plugin/request"
import utils from "@/common/utils"
import lodash from "lodash"
import vuetify from './plugin/vuetify';

import ElementUI from 'element-ui';
//引入element-ui的默认CSS样式
import '@/assets/css/element.scss';
//加载特殊字体
import 'font-awesome/scss/font-awesome.scss'
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@/assets/css/main.css'
import { Plugin } from 'vue-fragment';
//默认自定义公共组件
import CommonComponents from "@/components/common";
//注册统一指令
import "@/common/lib/directive"
//引入多语言
import getLang from "./__i18n__"
import VueI18n from "vue-i18n"
// 引入firebase
import firebase from "@iqiyi-ibd-bdfe/firebase"

//引入登陆sdk,并初始化
const globalLoginSdk = require('@iqiyi-ibd/global-login-sdk')
const sdk = new globalLoginSdk.LoginSdk({
  agenttype: 425, 
  lang: 'zh_cn',
  ptid : "01010781010011000000"
})
sdk.init();

Vue.use(ElementUI)
//fragment插件
Vue.use(Plugin)
Vue.use(CommonComponents)

Vue.config.productionTip = false

//全局变量挂载
window.utils = utils;
window.$$ = axios;
window.request = request;
window._ = lodash;

//window在模板中无法访问，挂载到Vue对象中引用访问
Vue.prototype.global = window;
Vue.prototype.sdk = sdk;

//添加事件总线
Vue.prototype.bus = new Vue();

firebase({
  apiKey: "AIzaSyCZQ85HArRWlrM4TEzGKz6FpljVKTKplGg",
  authDomain: "web-frontend-e2685.firebaseapp.com",
  projectId: "web-frontend-e2685",
  storageBucket: "web-frontend-e2685.appspot.com",
  messagingSenderId: "590821321741",
  appId: "1:590821321741:web:ea2869382006cdae8fb944",
  measurementId: "G-D8P4RWJLMT"
})

getLang(router , store , App , Vue , VueI18n , ElementUI , axios)
