<template>
  <div class="search">
    <el-form class="full-width" :model="form" ref="search" @keydown.enter.native.prevent="searchAction">
      <div class="searchBase">
        <template v-if="!slotBase">
        <el-form-item :prop="mainSearch" class="mainInput">
          <el-input
              v-model="form[mainSearch]"
              :placeholder="placeholder"
          ></el-input>
        </el-form-item>
        </template>
        <slot v-else name="base"></slot>
        <div class="action">
              <el-button
              type="primary"
              size="small"
              @click="searchAction"
              >查询</el-button>
          <el-button
              type="primary"
              icon="fa fa-undo"
              size="small"
              @click="reset"
          ></el-button>
          <el-button
              type="primary"
              size="small"
              @click="advancedShow = !advancedShow"
              v-if="advanced"
              >高级搜索</el-button>
          <el-button
              type="primary"
              size="small"
              @click="exportAction"
              v-if="canExport"
              >导出</el-button>
        </div>
      </div>

      <el-collapse-transition>
      <div class="advanced-area directionrow" v-show="advancedShow">
          <slot></slot>
      </div>
      </el-collapse-transition>

    </el-form>
  </div>
</template>
<script>
export default {
  name: "Search",
  data() {
    return {
      mainSearch : "",
      advancedShow: false,
      slotBase: false,
      form: {

      }
    };
  },
  props:{
      name: String,
      placeholder:{
          type: String
      },
      value:{
          required: true,
          type: Object 
      },
      advanced: {
          default: true,
          type: Boolean
      },
      canExport: {
          default: false,
          type: Boolean
      }
  },
  methods: {
    searchAction(event){
       this.$emit('click');
    },
    reset() {
      this.form = {}
      this.$emit('reset');
    },
    exportAction(){
      this.$emit("export")
    }
  },
  created(){
    this.mainSearch = this.name ? this.name : 'searchContent'
    this.slotBase = this.$slots.base ? true : false
  },
  mounted(){
    // console.log(this.$slots)
  },
  watch:{
    value : {
        immediate: true,
        handler(val){
            this.form = val
        },
        deep : true
    },
    form : {
        handler(val){
            this.$emit("input" , val);
        },
        deep : true
    }
  },
  updated(){
  }
};
</script>
<style lang="scss">
.search{
    padding:20px 16px 8px;
    word-break: break-word;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background: #fff;
    margin-bottom:20px;
    .el-form-item__label{
        vertical-align: top;
    }
    .searchBase{
      display: flex;
      flex-wrap: wrap;
      .el-form-item{
        margin: 0 15px 12px 0;
      }
      .mainInput{
        width: 70%;
      }
    }
    .advanced-area{
        padding-top: 16px;
        overflow: hidden;
      .el-form-item{
        margin-bottom:12px;
      }
    }
}
</style>
